<template>
  <div class="dropdown" :class="{ 'dropdown--open': openChild }">
    <span
      class="material-symbols-outlined iconbox"
      :class="{ 'icon-open': openChild }"
      v-if="nome.substring(0, 4) === 'bk_1' || nome.substring(0, 5) === 'bkx_1'"
      >face</span
    >
    <span
      class="material-symbols-outlined iconbox"
      :class="{ 'icon-open': openChild }"
      v-if="nome.substring(0, 4) === 'bk_2' || nome.substring(0, 5) === 'bkx_2'"
      >business</span
    >
    <span
      class="material-symbols-outlined iconbox"
      :class="{ 'icon-open': openChild }"
      v-if="nome.substring(0, 4) === 'bk_3' || nome.substring(0, 5) === 'bkx_3'"
      >location_on</span
    >
    <span
      class="material-symbols-outlined iconbox"
      :class="{ 'icon-open': openChild }"
      v-if="nome.substring(0, 4) === 'bk_4' || nome.substring(0, 5) === 'bkx_4'"
      >directions_car</span
    >
    <div class="header" @click="openChild = !openChild">
      <div class="header__esq">
        <span class="nome"
          ><span class="material-symbols-outlined iconAtr"> check_small </span
          >{{ nome }}</span
        >
        <span class="descricao">{{ descricao }} </span>
      </div>
      <div class="header__dir">
        <span class="material-symbols-rounded">expand_more</span>
      </div>
    </div>
    <div class="corpo" :class="{ 'corpo--aberto': openChild }">
      <span class="listaParametro">
        <span class="nomePar">
          <span class="material-symbols-outlined iconParam"> output </span>
          <span> Parâmetros de Entrada: </span>
        </span>
        <span
          class="boxParam"
          v-for="(parametro, indexP) in parametros"
          :key="indexP"
        >
          <md-card class="cardParam" :id="indexP + nome">
            {{ parametro.Identificador }}
          </md-card>
          <b-tooltip
            :target="indexP + nome"
            triggers="hover"
            placement="bottom"
            variant="dark"
            class="tooltipResults"
            boundary="window"
          >
            {{ parametro.Descricao }}
          </b-tooltip>
        </span>
      </span>
      <div class="dropdown2" :class="{ 'dropdown--open': openChild2 }">
        <div class="header" @click="openChild2 = !openChild2">
          <div class="header__esq">
            <span class="nomeAtr">
              <span class="material-symbols-outlined iconAtr"> sort </span
              ><span> Atributos </span>
            </span>
          </div>
          <div class="header__dir">
            <span
              class="material-symbols-outlined iconTipo"
              :id="'tipoRetorno-' + nome"
              >assignment_returned</span
            >
            <span class="material-symbols-rounded">expand_more</span>
            <b-tooltip
              :target="'tipoRetorno-' + nome"
              triggers="hover"
              placement="bottom"
              variant="dark"
              class="tooltipResults"
              boundary="window"
            >
              <strong style="color: white">Tipo de Retorno:</strong> <br />
              {{ tipoRetorno }}
            </b-tooltip>
          </div>
        </div>
        <div class="corpo" :class="{ 'corpo--aberto': openChild2 }">
          <table id="tableComponent" class="table">
            <thead>
              <tr>
                <th v-for="field in fields" :key="field" class="text-left">
                  {{ field }}
                </th>
              </tr>
            </thead>
            <BookAtributos
              v-for="(atributo, index) in atributos"
              :key="index"
              :atributos="atributos"
              :atributo="atributo"
              :index="index"
              :pesquisaBook="pesquisaBook"
            >
            </BookAtributos>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BookAtributos from "@/components/Books/BookAtributos.vue";

export default {
  name: "Dropdown",
  components: {
    BookAtributos,
  },
  props: {
    nome: String,
    descricao: String,
    tipoRetorno: String,
    atributos: Array,
    parametros: Array,
    pesquisaBook: String,
  },

  data() {
    return {
      openChild: false,
      openChild2: false,
      fields: ["Identificador", "Descrição", "Tipo"],
    };
  },
  methods: {},

  mounted() {
    if (this.pesquisaBook != "" && this.pesquisaBook != undefined) {
      this.openChild = true;
      this.openChild2 = true;
    }
  },
};
</script>

<style lang="scss" scoped>
.dropdown {
  padding: 0.5rem;
  color: $bg-sidebar;
  background: rgb(255, 255, 255);

  border-radius: 10px;
  max-width: 100%;
  border: 1px solid transparent;
  display: flex;
  flex-direction: column;
  z-index: 1;
  position: relative;
  transition: 1s ease all;

  &--open {
    border: 1px solid $accent;
    max-height: 500px;
    overflow: hidden;
    // overflow-y: scroll;
  }
}

.dropdown2 {
  position: relative;
  padding: 0.5rem;
  color: $bg-sidebar;
  background: $white;

  z-index: 2;
  border-radius: 10px;
  max-width: 100%;
  margin: 10px;
  border: 1px solid transparent;
  display: flex;
  flex-direction: column;
  box-shadow: 1px 0 10px #aaa;
}

@keyframes sobe {
  0% {
    transform: translateY(200px);
  }

  100% {
    transform: translateY(0px);
  }
}

.header {
  cursor: pointer;

  display: flex;
  justify-content: space-between;

  &__esq {
    display: flex;
    flex-direction: column;

    .nome {
      color: $accent;
      font-weight: 600;
      font-size: 1.2rem;
      display: flex;
      align-items: flex-end;
    }
  }
}

.nomeAtr {
  color: $accent;
  font-weight: 500;
  font-size: 1.1rem;
  display: flex;
}

.nomePar {
  font-weight: 500;
  font-size: 1rem;
  display: flex;
}

.iconAtr {
  margin-right: 0.2rem;
}

.iconParam {
  margin-right: 0.2rem;
}

.iconTipo {
  margin: 0rem 1rem 0 0;
  padding: 0.3rem 0.2rem 0.2rem 0.2rem;
  cursor: default;
  opacity: 0.5;

  &:hover {
    box-shadow: 1px 0 10px #aaa;
  }
}

.corpo {
  display: flex;
  flex-direction: column;
  overflow-x: auto;
  height: 0;

  &--aberto {
    height: fit-content;
    padding-top: 1rem;
  }
}

.listaParametro {
  position: relative;
  background: white;
  z-index: 2;
  max-width: 98%;
  margin: 0 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}

.cardParam {
  margin: 5px 0px 5px 20px;
  padding: 5px 10px;
  width: auto;
  min-width: 50px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  cursor: default;
  box-shadow: 1px 0 5px #aaa;
}

.cardParam:hover {
  background: $white;
  box-shadow: 1px 0 10px #aaa;
}

.descricao {
  font-size: 1rem;
  font-weight: 500;
  margin: 0 10px;
}
.iconbox {
  position: absolute;
  right: 0;
  top: 0%;
  margin-right: 30px;
  font-size: 70px;
  opacity: 0.1;
}

.icon-open {
  font-size: 120px;
  right: 0;
  margin-right: 30px;
  z-index: 3;
  user-select: none;
}
</style>
