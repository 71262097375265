<template>
  <div
    class="janela-chat"
    :style="
      tipoAssistenteSelecionado == 'singuSuperbox' ? 'width: 60%' : 'width: 40%'
    "
  >
    <div class="corpo-chat" ref="chatContainer">
      <div
        v-for="(mensagem, indice) in historicoChat"
        :key="indice"
        :style="{
          'justify-content':
            mensagem.enviado === true
              ? 'flex-end'
              : mensagem.enviado === false
              ? 'flex-start'
              : 'center',
        }"
        class="box-mensagem"
      >
        <span class="fundo-logo-ia" v-if="!mensagem.enviado">
          <img
            v-for="imagem in imagens"
            :src="imagem.src"
            :key="imagem.key"
            :class="{
              ativo: imagemAtual === imagem.key,
              'robo-gif': !imagem.key.includes('Padrao'),
            }"
            class="logo-ia"
          />
        </span>
        <div
          v-if="mensagem.divisor"
          style="display: flex; width: 100%; justify-content: center"
        >
          <div
            v-if="mensagem.divisor"
            v-html="mensagem.texto"
            class="mensagem-divisor"
          ></div>
        </div>
        <div
          v-else
          :class="{
            'mensagem-chat': true,
            enviado: mensagem.enviado,
            recebido: !mensagem.enviado,
          }"
        >
          <div v-if="mensagem.tipo == 'texto'" v-html="mensagem.texto"></div>
          <div v-if="mensagem.tipo === 'imagem' && mensagem.imagem != ''">
            <img
              :src="'data:image/png;base64,' + mensagem.imagem"
              alt="Imagem do chat"
              class="chat-imagem"
              @click="abrirPopup('data:image/png;base64,' + mensagem.imagem)"
            />
          </div>
          <div v-if="mensagem.tipo === 'texto-link'">
            <span v-html="mensagem.textoAntes"></span>
            <a
              href="#"
              @click="liberarLink(mensagem.tipoDeLink)"
              v-html="mensagem.link"
            ></a>
            <span v-html="mensagem.textoDepois"></span>
          </div>
          <div class="hora-msg">{{ mensagem.hora }}</div>
        </div>
      </div>
    </div>
    <div class="chat-container">
      <textarea
        v-model="entradaChatTemp"
        placeholder="Mensagem..."
        @keydown="verificarEnvio"
        class="entrada-chat"
        maxlength="300"
        @input="checkTransicao"
      >
      </textarea>
      <div class="contador">{{ entradaChatTemp.length }}/300</div>
      <span
        v-if="!carregandoResposta && !carregandoRequisicao"
        class="material-symbols-outlined botao-enviar"
        @click="enviarChat()"
        :class="{
          disabled:
            entradaChatTemp == '' || carregandoResposta || !termosUsoAssistente,
        }"
      >
        send
      </span>
      <span
        v-else-if="!carregandoRequisicao"
        class="material-symbols-outlined botao-enviar disabled"
        @click="stopSimulacao()"
        :class="{ disabled: carregandoRequisicao }"
      >
        stop_circle
      </span>
      <md-progress-spinner
        v-if="carregandoRequisicao"
        :md-diameter="25"
        :md-stroke="2"
        md-mode="indeterminate"
        class="botao-enviar loading"
      >
      </md-progress-spinner>
      <div class="chat-aviso">
        Singú está sujeito a falhas. Sempre confira as informações.
      </div>
      <div class="chat-ajuda">
        <img
          :src="iconeCarrossel"
          class="icone-ajuda"
          alt="icone ajuda assistente outbox"
          ref="iconeAjuda"
          @click="abreCarrossel = !abreCarrossel"
        />
        <img
          :src="iconeListaPerguntas"
          class="icone-ajuda"
          alt="icone sugestões assistente outbox"
          ref="iconeAjudaPerguntas"
          style="top: 1px"
          @click="abreListaPerguntas = !abreListaPerguntas"
        />
      </div>
    </div>
    <div v-if="imagemPopup" class="popup-overlay" @click.self="fecharPopup">
      <div class="popup-content" ref="popupContent">
        <img
          :src="imagemPopup"
          alt="Imagem expandida"
          class="imagem-expandida"
          :style="{
            transform: `scale(${zoomLevel})`,
          }"
          ref="imagemExpandida"
          @wheel="handleZoomWheel"
        />
      </div>
      <div class="zoom-controls">
        <span
          class="material-symbols-outlined botoes-ajuste"
          @click="fecharPopup"
        >
          close
        </span>
        <span
          class="material-symbols-outlined botoes-ajuste add"
          @click="zoomIn"
          :class="{ disabled: zoomLevel == 3 }"
        >
          zoom_in
        </span>
        <span
          class="material-symbols-outlined botoes-ajuste remove"
          @click="zoomOut"
          :class="{ disabled: zoomLevel == 1 }"
        >
          zoom_out
        </span>
        <span
          class="material-symbols-outlined botoes-ajuste restore"
          @click="restauraZoom"
        >
          crop_free
        </span>
      </div>
    </div>
    <div
      v-if="abreCarrossel"
      class="popup-overlay"
      @click.self="fechaCarrossel()"
    >
      <div class="card-carrossel">
        <CarrosselSingu @fechaCarrossel="fechaCarrossel" />
      </div>
    </div>
    <div
      v-if="abreListaPerguntas"
      class="popup-overlay"
      @click.self="fechaListaPerguntas()"
    >
      <ListaPerguntasSugeridas
        @enviaPerguntaSelecionada="enviaPerguntaSelecionada"
      />
    </div>
  </div>
</template>

<script>
import { chatOutboxStore } from "@/stores/chat-Outbox.js";
import { userPreferenceStore } from "@/stores/userPreference";
import { mapWritableState, mapActions } from "pinia";
import CarrosselSingu from "./CarrosselSingu.vue";
import ListaPerguntasSugeridas from "./ListaPerguntasSugeridas.vue";

export default {
  name: "SaidaChatOutbox",

  components: { CarrosselSingu, ListaPerguntasSugeridas },

  props: {
    historicoChat: Array,
    entradaChat: String,
    thread_id: String,
    imagemAtual: String,
    tipoAssistenteSelecionado: String,
    carregandoResposta: Boolean,
    carregandoRequisicao: Boolean,
  },

  data() {
    return {
      timerId: null,
      entradaChatTemp: "",

      dataFormatada: "",
      horaFormatada: "",

      contextoAtual: {
        codigo_agrupador: "",
        rota: "/inicio",
        nome: "",
        descricao: "",
      },

      imagemPopup: null,
      zoomLevel: 1,
      currentOffsetX: 0,
      currentOffsetY: 0,
      maxZoom: 3,
      minZoom: 1,
      zoomStep: 0.2,

      linkTermos: "/docs/Termos de serviço - Assistente Outbox.pdf",

      textoCompletoSimulado: "",
      idIntervaloSimulacao: 0,

      checkTermosUsoAssistente: false,

      imagens: [
        {
          key: "logoIAPadrao",
          src: "/img/assistente-chat/Assistente-Outbox-padrao-floating.gif",
        },
        {
          key: "logoRoboParaPonto",
          src: "/img/assistente-chat/Assistente-Outbox-RoboParaPontos.gif",
        },
        {
          key: "logoDigitando",
          src: "/img/assistente-chat/Assistente-Outbox-Pontos.gif",
        },
        {
          key: "logoPontoParaRobo",
          src: "/img/assistente-chat/Assistente-Outbox-PontoParaRobo.gif",
        },
      ],

      iconeCarrossel: "/img/assistente-chat/icone_carrossel_chat.svg",
      iconeListaPerguntas:
        "/img/assistente-chat/icone-lista-perguntas-chat.svg",

      abreCarrossel: false,
      abreListaPerguntas: false,
    };
  },

  watch: {},

  methods: {
    fechaCarrossel() {
      this.abreCarrossel = false;
    },
    fechaListaPerguntas() {
      this.abreListaPerguntas = false;
    },
    checkTransicao() {
      this.$emit("checkTransicao", this.entradaChatTemp);
    },

    stopSimulacao() {
      this.$emit("stopSimulacao");
    },

    enviarChat() {
      this.$emit("enviarChat", this.entradaChatTemp);
      this.entradaChatTemp = "";
    },

    verificarEnvio(event) {
      if (
        !this.carregandoResposta &&
        !this.carregandoRequisicao &&
        event.key === "Enter" &&
        !event.shiftKey
      ) {
        event.preventDefault();
        this.enviarChat();
      }
    },

    abrirPopup(src) {
      this.imagemPopup = src;
    },

    fecharPopup() {
      this.imagemPopup = null;
      this.zoomLevel = 1;
      this.currentOffsetX = 0;
      this.currentOffsetY = 0;
    },

    zoomIn() {
      if (this.zoomLevel <= this.maxZoom) {
        this.zoomLevel = Math.min(this.zoomLevel + this.zoomStep, this.maxZoom);
        this.adjustImagePosition();
      }
    },
    zoomOut() {
      if (this.zoomLevel >= this.minZoom) {
        this.zoomLevel = Math.max(this.zoomLevel - this.zoomStep, this.minZoom);
        this.adjustImagePosition();
      }
    },

    adjustImagePosition() {
      const img = this.$refs.imagemExpandida;
      const container = this.$refs.popupContent;

      if (this.zoomLevel != 1) {
        if (img.offsetWidth * this.zoomLevel > 855) {
          img.style.transformOrigin = "top left";
          img.style.right = "100px";
        } else {
          img.style.transformOrigin = "top center";
          img.style.right = "0px";
        }
      } else {
        img.style.transformOrigin = "top center";
        img.style.right = "0px";
        this.currentOffsetY = 0;
        this.currentOffsetX = 0;
      }
    },

    handleZoomWheel(event) {
      if (event.ctrlKey) {
        event.preventDefault();
        if (event.deltaY > 0) {
          this.zoomOut();
        } else {
          this.zoomIn();
        }
      }
    },

    restauraZoom() {
      this.zoomLevel = 1;
      this.currentOffsetY = 0;
      this.currentOffsetX = 0;

      const img = this.$refs.imagemExpandida;
      img.style.transformOrigin = "top center";
      img.style.right = "0px";
    },

    updateImagePosition() {
      const img = this.$refs.imagemExpandida;
      img.style.transform = `scale(${this.zoomLevel}) translate(${this.translateX}px, ${this.translateY}px)`;
    },

    enviaPerguntaSelecionada(pergunta) {
      this.abreListaPerguntas = false;
      this.entradaChatTemp = "";
      this.entradaChatTemp = pergunta;
      this.checkTransicao();
    },

    atualizaHora() {
      const agora = new Date();
      this.dataFormatada = "";
      this.dataFormatada = agora.toLocaleDateString("pt-BR");
      this.horaFormatada = "";
      this.horaFormatada = agora.toLocaleTimeString("pt-BR", {
        hour: "2-digit",
        minute: "2-digit",
      });
    },

    scrollParaBaixo() {
      const chatContainer = this.$refs.chatContainer;
      if (chatContainer) {
        chatContainer.scrollTop = chatContainer.scrollHeight;
      }
    },

    liberarLink(link) {
      this.abreListaPerguntas = false;
      this.abreCarrossel = false;

      if (link == "[link_carrossel]") {
        this.abreCarrossel = true;
      }

      if (link == "[link_perguntas]") {
        this.abreListaPerguntas = true;
      }
    },

    liberarListaPerguntas() {
      this.abreListaPerguntas = true;
    },

    ...mapActions(chatOutboxStore, [
      "resetStoreChat",
      "requisicaoChat",
      "abrirJanelaChat",
      "fecharJanelaChat",
    ]),

    ...mapActions(userPreferenceStore, [
      "syncPreferencias",
      "createPreferencia",
      "substituiPreferencia",
      "syncPreferenciaGeral",
    ]),
  },

  computed: {
    digitando() {
      if (this.entradaChat.length > 0) {
        return true;
      } else {
        return false;
      }
    },

    ...mapWritableState(chatOutboxStore, {
      contexto: "contexto",
      resultadoRequisição: "resultadoRequisição",
    }),

    ...mapWritableState(userPreferenceStore, {
      usuarioLogado: "usuarioLogado",
      termosUsoAssistente: "termosUsoAssistente",
    }),
  },

  async mounted() {},
};
</script>

<style lang="scss" scoped>
.janela-chat {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100%;

  @include mediaQueryMax(medium) {
    width: 80% !important;
  }
}

.fundo-logo-ia {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  border: 2px solid #007bff;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  min-width: 55px;
  min-height: 55px;
  margin-bottom: 2px;
  // overflow: hidden;
}

.logo-ia {
  width: 37px;
  height: 37px;
  transition: opacity 0.3s ease-in-out;
  opacity: 0;
  display: none;

  &.ativo {
    display: flex;
    opacity: 1;
  }
}

.chat-imagem {
  transition: transform 0.5s;
  cursor: pointer;

  &:hover {
    transform: scale(1.01);
  }
}

.robo-gif {
  width: 35px;
  height: 35px;
}

.icones-box {
  display: flex;
  gap: 20px;
  cursor: default;
  align-items: center;
}

.corpo-chat {
  min-height: 420px;
  height: 100%;
  overflow-y: auto;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  width: 100%;
  padding-top: 2px;
}

.box-mensagem {
  display: flex;
  align-items: flex-end;
  text-align: center;
}

.mensagem-divisor {
  display: flex;
  align-items: center;
  text-align: center;
  font-style: italic;
  color: #888;
  font-size: 12px;
  font-weight: 300;
  line-height: 14px;
  width: 100%;
  background-color: rgb(252, 252, 252);
  border-radius: 15px;
  padding: 5px;
  margin: 10px 5px;
}

.mensagem-divisor:before,
.mensagem-divisor:after {
  content: "";
  flex: 1;
  border-bottom: 1px solid #ccc;
}

.mensagem-divisor:before {
  margin-right: 10px;
}

.mensagem-divisor:after {
  margin-left: 10px;
}

.mensagem-chat {
  position: relative;
  margin: 10px;
  background-color: #6d6d6d;
  border-radius: 10px;
  max-width: 85%;
  padding: 10px;
  font-size: 14px;
  font-weight: 300;
  line-height: 18px;
  cursor: default;
  word-break: break-word;
  width: fit-content;
}

.enviado {
  background-color: #703eff;
  color: white;
  text-align: right;
  align-self: flex-end;
}

.recebido {
  background-color: rgb(251, 251, 251);
  color: black;
  text-align: left;
  align-self: flex-start;
}

.enviado::after {
  content: "";
  position: absolute;
  bottom: 4px;
  right: -5px;
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-left-color: #703eff;
  transform: rotate(-90deg) scale(1.4);
  border-radius: 20%;
}

.recebido::after {
  content: "";
  position: absolute;
  bottom: 4px;
  left: -5px;
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-right-color: rgb(251, 251, 251);
  transform: rotate(90deg) scale(1.4);
  border-radius: 20%;
}

.hora-msg {
  font-size: 12px;
  font-weight: 400;
  width: 100%;
  text-align: right;
}

.chat-container {
  position: relative;
  width: 100%;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
}

.entrada-chat {
  width: 100%;
  border: none;
  background-color: #ffffff;
  color: black;
  height: 7cap;
  border-radius: 30px;
  max-height: 70px;
  min-height: 70px;
  font-size: 14px;
  font-weight: 300;
  position: relative;
  top: 5px;
  padding: 15px 85px 15px 20px;
  line-height: 16px;
}

.contador {
  position: absolute;
  right: 45px;
  bottom: 78px;
  background-color: transparent;
  color: #666;
  font-size: 12px;
  line-height: 16px;
}

.botao-enviar {
  position: absolute;
  right: 15px;
  bottom: 78px;
  background-color: transparent;
  color: #0389dd;
  font-size: 26px;
  cursor: pointer;
  transition: 0.3s;
  opacity: 0.5;
  line-height: 16px;

  &.loading {
    bottom: 75px;
    cursor: default;
  }

  &:hover {
    opacity: 0.7;
  }
}

.chat-aviso {
  font-size: 12px;
  font-weight: 300;
  line-height: 17px;
  text-align: center;
  color: #686868;
  position: relative;
  top: 4px;
}

.chat-ajuda {
  font-size: 12px;
  font-weight: 300;
  line-height: 17px;
  text-align: center;
  display: flex;
  gap: 10px;
  margin: 2px 0;
}

.icone-ajuda {
  cursor: pointer;
  transition: 0.3s;
  position: relative;
  opacity: 0.8;

  &:hover {
    opacity: 1;
    transform: scale(1.05);
  }

  &:active {
    opacity: 1;
    transform: scale(0.95);
  }
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  position: relative;
  background: white;
  padding: 10px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: width 0.2s ease-in-out;
  width: 60%;
  height: 90%;
  overflow: auto;
  transform: translateX(10%);

  &.carrossel {
    background: transparent;
    width: 50%;
    overflow: hidden;
  }
}

.imagem-expandida {
  max-width: 95%;
  max-height: 95%;
  transition: transform 0.2s ease-in-out;
  transform-origin: top center;
  position: relative;
}

.zoom-controls {
  display: flex;
  gap: 10px;
  flex-direction: column;
  align-items: center;
  height: 86%;
  width: 50px;
  position: relative;
  left: 2%;
}

.botoes-ajuste {
  color: #585858;
  cursor: pointer;
  font-size: 26px;
  transition: 0.3s;
  z-index: 10;

  &:hover {
    transform: scale(1.05);
  }
}

.card-carrossel {
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 30px 30px 30px rgba(23, 55, 127, 0.3);
  display: flex;
  flex-direction: column;
  padding: 20px;
  place-items: start;
  transition: 0.5s;
  height: 555px;
  width: 460px;
  margin: 20px auto;
  border: none;

  &:hover {
    transform: scale(1.05);
  }
}

.disabled {
  pointer-events: none;
  color: #a1a1a1;
}

.entrada-chat::placeholder {
  color: #303030;
  font-size: 16px;
  opacity: 1;
  line-height: 17px;
}

.corpo-chat::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.corpo-chat::-webkit-scrollbar-thumb {
  background-color: #aaaaaa;
  border-radius: 10px;
}

.corpo-chat::-webkit-scrollbar-track {
  background: #dadada;
}

.entrada-chat::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.entrada-chat::-webkit-scrollbar-thumb {
  background-color: #aaaaaa;
  border-radius: 10px;
}

.entrada-chat::-webkit-scrollbar-track {
  background: white;
}
</style>

<style>
/* .swal2-input-chat {
  margin: 0;
  box-shadow: none;
  border: none;
  border-bottom: 1px solid #cbbfbf;
  border-radius: 0;
  width: 250px;
  height: 30px;
  background: transparent;

}
.swal2-input-chat .option {
  background-color: #EEEEEE;
  border: none;
} */
</style>
