var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[(_vm.paginatedData.length > 0)?_c('table',[_c('thead',[_c('tr',_vm._l((_vm.headerStatus),function(col){return _c('th',{key:col,staticClass:"cabecalho",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.sortColumn(col)}}},[_vm._v(" "+_vm._s(col)+" "),_c('span',{staticClass:"sort-arrow",class:{ active: _vm.sortedColumn === col }},[_vm._v(" "+_vm._s(_vm.sortedColumn === col && _vm.sortDirection === "asc" ? "▲" : "▼")+" ")])])}),0)]),_c('tbody',_vm._l((_vm.paginatedData),function(row,index){return _c('tr',{key:index,staticClass:"linhas"},_vm._l((row),function(value,key){return _c('td',{key:key,staticClass:"cell",class:{ total: row.Grupo == 'Total' }},[_vm._v(" "+_vm._s(value)+" "),(_vm.hasHistorico && row.Grupo != 'Total')?_c('span',[(
                _vm.getComparison(row.Grupo, key).direction === 'up' &&
                (key == 'Media_Churn' || key == 'Media_Inad')
              )?_c('span',{staticClass:"seta-cima legenda"},[_vm._v(" ↑ +"+_vm._s(_vm.getComparison(row.Grupo, key).difference)+" ")]):(
                _vm.getComparison(row.Grupo, key).direction === 'down' &&
                (key == 'Media_Churn' || key == 'Media_Inad')
              )?_c('span',{staticClass:"seta-baixo legenda"},[_vm._v(" ↓ -"+_vm._s(_vm.getComparison(row.Grupo, key).difference)+" ")]):(key == 'Media_Churn' || key == 'Media_Inad')?_c('span',{staticClass:"seta-direita legenda"},[_vm._v(" → 0 ")]):_vm._e()]):_vm._e()])}),0)}),0)]):_c('div',{staticClass:"aviso"},[_vm._v("Nenhuma cota adicionada")]),(_vm.valoresTabelaStatus.length > _vm.rowsPerPage)?_c('div',{staticClass:"pagination"},[_c('span',{staticClass:"material-symbols-outlined seta-pagina",class:{ disabled: _vm.currentPage === 1 },on:{"click":_vm.previousPage}},[_vm._v(" keyboard_double_arrow_left ")]),_c('span',{staticClass:"texto-pagina"},[_vm._v("Página "+_vm._s(_vm.currentPage)+" de "+_vm._s(_vm.totalPages))]),_c('span',{staticClass:"material-symbols-outlined seta-pagina",class:{ disabled: _vm.currentPage === _vm.totalPages },on:{"click":_vm.nextPage}},[_vm._v(" keyboard_double_arrow_right ")])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }