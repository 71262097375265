var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[(_vm.paginatedData.length > 0)?_c('table',[_c('thead',[_c('tr',_vm._l((_vm.headerGrupos),function(col,index){return _c('th',{key:col,staticClass:"cabecalho",class:{
            indice: index == 0,
            grupo: index == 1,
            cpf: index == 2,
            nome: index == 3,
            data: index == 4,
            score: index == 5 || index == 6,
          },staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.sortColumn(col)}}},[_c('span',{staticClass:"sub"},[_vm._v(" "+_vm._s(col)+" "),(col != '')?_c('span',{staticClass:"sort-arrow",class:{ active: _vm.sortedColumn === col }},[_vm._v(" "+_vm._s(_vm.sortedColumn === col && _vm.sortDirection === "asc" ? "▲" : "▼")+" ")]):_vm._e()])])}),0)]),_c('tbody',_vm._l((_vm.filteredData),function(row,index){return _c('tr',{key:index,staticClass:"linhas",class:{
          'nova-linha': _vm.isNovaLinha(row),
        }},[_c('div',{staticClass:"cell indice"},[(_vm.isNovaLinha(row))?_c('span',{staticClass:"alerta-novo",class:{
              'ultima-linha': _vm.isPrimeiraNovaLinha(row),
            }},[_vm._v(" NEW ")]):_vm._e()]),_vm._l((row.displayRow),function(value,key){return _c('td',{key:key,staticClass:"cell"},[_vm._v(" "+_vm._s(value)+" ")])})],2)}),0)]):_c('div',{staticClass:"aviso"},[_vm._v("Nenhuma cota adicionada")]),_c('div',{staticClass:"paginas"},[_c('span',{staticClass:"material-symbols-outlined pag-botao",class:{ disabled: _vm.currentPage === 1 },on:{"click":function($event){return _vm.irParaPagina(1)}}},[_vm._v(" keyboard_double_arrow_left ")]),_c('span',{staticClass:"material-symbols-outlined pag-botao",class:{ disabled: _vm.currentPage === 1 },on:{"click":_vm.paginaAnterior}},[_vm._v(" chevron_left ")]),_vm._l((_vm.paginasVisiveis),function(pagina){return _c('span',{key:pagina,staticClass:"box-pagina"},[_c('button',{class:['botao-pagina', { 'pagina-ativa': pagina === _vm.currentPage }],on:{"click":function($event){return _vm.irParaPagina(pagina)}}}),_c('span',{staticClass:"legenda-pagina"},[_vm._v(_vm._s(pagina))])])}),_c('span',{staticClass:"material-symbols-outlined pag-botao",class:{ disabled: _vm.currentPage === _vm.totalPaginas },on:{"click":_vm.proximaPagina}},[_vm._v(" chevron_right ")]),_c('span',{staticClass:"material-symbols-outlined pag-botao",class:{ disabled: _vm.currentPage === _vm.totalPaginas },on:{"click":function($event){return _vm.irParaPagina(_vm.totalPaginas)}}},[_vm._v(" keyboard_double_arrow_right ")])],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }