<template>
  <div>
    <div class="container" v-if="parametrosUtilizados.length > 0">
      <div class="favoritos">
        <md-field class="selectFav">
          <label
            for="parametrosFavoritos"
            style="display: flex; align-items: center"
          >
            <span
              class="material-symbols-outlined star"
              :class="{ vazio: parametrosFavoritos.length == 0 }"
            >
              grade
            </span>
            Parâmetros Favoritos
          </label>
          <md-select
            v-model="favoritoSelecionado"
            name="parametrosFavoritos"
            id="parametrosFavoritos"
            :disabled="parametrosFavoritos.length == 0 || !liberaInputs"
            @input="preencheParametros()"
          >
            <md-option :value="''" style="color: gray !important"
              >--Vazio--</md-option
            >
            <span
              v-for="(parametros, index) in parametrosFavoritos"
              :key="index"
            >
              <md-option
                :value="parametros.descricao"
                :id="'opcao-' + index"
                style="white-space: wrap"
              >
                {{ parametros.descricao }}
              </md-option>
              <b-tooltip
                :target="'opcao-' + index"
                triggers="hover"
                placement="bottom"
                variant="dark"
                boundary="window"
                customClass="tooltip-custom"
              >
                <span
                  class="results-text"
                  v-for="(nome, i) in Object.keys(parametros)"
                  :key="i"
                >
                  <div class="results-text" v-if="i != 0">
                    <strong style="color: white"> {{ nome }}: </strong>
                    {{ parametros[nome] }}
                  </div>
                </span>
              </b-tooltip>
            </span>
          </md-select>
        </md-field>
      </div>
      <div class="campos">
        <div
          v-for="parametro in parametrosUtilizados"
          :key="parametro.nome_api"
          style="width: 100%"
          class="campo-box"
          :style="{ display: parametro.visivel ? 'flex' : 'none' }"
        >
          <md-field
            v-if="parametro.visivel"
            class="box-inputs no-counter"
            :class="parametro.Classe"
          >
            <label>
              {{ parametro.nome_amigavel }}
              <span v-if="parametro.Tipo == 'Data'"> (dd/mm/aaaa) </span>
            </label>

            <md-input
              v-if="
                parametro.dominio == undefined &&
                parametro.tipo_input != 'numero-dominio' &&
                parametro.tipo_input != 'selecao' &&
                parametro.Tipo != 'Inteiro'
              "
              v-model="parametro.Valor"
              :required="parametro.Obrigatorio"
              :type="
                parametro.nome_amigavel == 'CPF' &&
                (ocultarDados || cpfEhFavorito)
                  ? 'password'
                  : 'text'
              "
              :placeholder="''"
              :maxlength="parametro.tamanho"
              :disabled="!liberaInputs"
              @input="aplicarMascara(parametro)"
              @keyup.enter="decideConsulta()"
              @keydown="filtrarTeclado($event, parametro)"
            />
            <button
              class="date-btn"
              @click="abreEscolhaData(parametro)"
              :disabled="!liberaInputs"
            >
              <span
                class="material-icons-outlined calendar"
                v-if="
                  (parametro.tipo_input == 'data' ||
                    parametro.Tipo == 'Data') &&
                  (liberaDatePicker[parametro.nome_api] == false ||
                    liberaDatePicker[parametro.nome_api] == undefined)
                "
              >
                calendar_month
              </span>
              <span
                class="material-icons-outlined calendar"
                v-if="
                  (parametro.tipo_input == 'data' ||
                    parametro.Tipo == 'Data') &&
                  liberaDatePicker[parametro.nome_api] == true
                "
              >
                event_busy
              </span>
            </button>
            <b-calendar
              v-if="
                (parametro.tipo_input == 'data' || parametro.Tipo == 'Data') &&
                liberaDatePicker[parametro.nome_api] == true
              "
              :max="new Date()"
              class="box-calendar"
              v-model="parametro.ValorDatePicker"
              @input="selecionaData($event, parametro)"
              locale="pt-BR"
              :hide-header="true"
              nav-button-variant="primary"
              label-help="Selecione uma data"
              :initial-date="
                parametro.Valor != undefined || parametro.Valor != ''
                  ? formatDateYMD(parametro.Valor)
                  : formatDateYMD(new Date())
              "
            ></b-calendar>
            <md-input
              v-if="
                parametro.dominio == undefined &&
                parametro.tipo_input != 'numero-dominio' &&
                parametro.Tipo == 'Inteiro'
              "
              v-model="parametro.Valor"
              :required="parametro.Obrigatorio"
              :type="'number'"
              :placeholder="''"
              :maxlength="parametro.tamanho"
              :disabled="!liberaInputs"
              @input="aplicarMascara(parametro)"
              @keyup.enter="decideConsulta()"
              @keydown="filtrarTeclado($event, parametro)"
            />
            <md-input
              class="no-counter"
              v-if="
                parametro.dominio != undefined &&
                parametro.tipo_input == 'numero-dominio'
              "
              v-model="parametro.Valor"
              :required="parametro.Obrigatorio"
              :type="'number'"
              :maxlength="parametro.tamanho"
              :disabled="!liberaInputs"
              @input="filtrarDominio(parametro)"
              @keyup.enter="decideConsulta()"
              @keydown="filtrarTeclado($event, parametro)"
            />
            <span
              v-if="
                parametro.dominio != undefined &&
                parametro.tipo_input == 'numero-dominio'
              "
              class="placeholder-text"
            >
              {{ parametro.placeholderText }}
            </span>

            <md-select
              v-if="
                parametro.tipo_input == 'selecao' &&
                parametro.dominio.length < 5
              "
              name="select-modelos"
              :id="'select-modelos-' + parametro.nome_api"
              v-model="parametro.Valor"
              class="box-select"
              :disabled="!liberaInputs"
            >
              <span v-for="(opcao, index) in parametro.dominio" :key="index">
                <md-option
                  :value="opcao.valor"
                  :id="'opcao-' + index"
                  style="white-space: wrap"
                >
                  {{ opcao.descricao }}
                </md-option>
              </span>
            </md-select>
            <md-select
              v-if="
                parametro.tipo_input == 'selecao' &&
                parametro.dominio.length >= 5
              "
              name="select-modelos"
              :id="'select-modelos-' + parametro.nome_api"
              v-model="parametro.Valor"
              :placeholder="parametro.nome_amigavel"
              @click="clearSearch"
              ref="meuSelect"
              select-clear
              :disabled="!liberaInputs"
            >
              <div class="textSearch">
                <md-input
                  v-model="searchSelect"
                  class="textSearchInput"
                  placeholder="Pesquise um valor"
                >
                </md-input>
              </div>
              <span
                v-for="(opcao, index) in optionsListResposta(parametro.dominio)"
                :key="index"
              >
                <md-option
                  :value="opcao.valor"
                  :id="'opcao-' + index"
                  style="white-space: wrap"
                >
                  {{ opcao.descricao }}
                </md-option>
              </span>
            </md-select>
          </md-field>
        </div>
      </div>
      <md-card-actions class="btn-center">
        <button class="botao-acao" @click="retornaGrupos()">Voltar</button>
        <button
          v-if="liberaInputs"
          :disabled="!liberaEnviar || liberaSpinner"
          :class="{
            disabled: !liberaEnviar || liberaSpinner,
          }"
          class="botao-acao"
          @click="decideConsulta()"
        >
          Consultar
        </button>
        <button
          v-if="!liberaInputs"
          class="botao-acao"
          @click="alteraLiberaInput(true)"
        >
          Alterar
        </button>
      </md-card-actions>
    </div>
    <div class="loading" v-if="liberaSpinner">
      <Loading />
    </div>
  </div>
</template>

<script>
import Loading from "@/components/Loading.vue";
import inputsModelos from "@/helpers/inputs_modelos.json";
import favoritosCases from "@/helpers/favoritos_consorcio.json";

import { mapWritableState, mapActions } from "pinia";
import { chatOutboxStore } from "@/stores/chat-Outbox.js";
import { userPreferenceStore } from "@/stores/userPreference";
import { dadosConsultaAddStore } from "@/stores/dadosConsultaAdd";
import { lotesService } from "@/services";
import {
  validadorCep,
  validadorCpf,
  validadorCnpj,
  validadorDataPtbr,
} from "@/validadores";

export default {
  name: "preenchimentoCampos",

  props: {
    opcao_inicial: String,
    modeloSelecionado: Object,
    liberaInputs: Boolean,
  },

  data() {
    return {
      layoutModelo: {},
      liberaEnviar: false,
      resultados: [],
      parametrosUtilizados: [],
      searchSelect: "",
      liberaSpinner: false,
      liberaDatePicker: {},

      imprimeParametro: [],
      favoritoSelecionado: {},
    };
  },

  components: {
    Loading,
  },

  watch: {
    parametrosUtilizados: {
      deep: true,
      handler: "validaConsultar",
    },
  },

  methods: {
    retornaGrupos() {
      this.$emit("retornaGrupos");
    },

    alteraLiberaInput(valor) {
      this.$emit("alteraLiberaInput", valor);
    },

    preencheParametros() {
      const parametro = this.parametrosFavoritos.find(
        (param) => param.descricao === this.favoritoSelecionado
      );

      this.parametrosUtilizados.forEach((campo) => {
        if (parametro) {
          if (campo.visivel) {
            if (campo.nome_api === "dth_inicio") {
              const hoje = new Date();
              const dia = String(hoje.getDate()).padStart(2, "0");
              const mes = String(hoje.getMonth() + 1).padStart(2, "0");
              const ano = hoje.getFullYear();
              campo.Valor = `${dia}/${mes}/${ano}`;
            } else if (parametro.hasOwnProperty(campo.nome_api)) {
              campo.Valor = parametro[campo.nome_api];
            }
          }
        }

        if (this.favoritoSelecionado == "") {
          delete campo.Valor;
        }

        this.selecionaClasseInput(campo);
        this.validaConsultar();
      });

      this.verificaDocumentoFavorito();
    },

    verificaDocumentoFavorito() {
      const parametroCpf = this.parametrosUtilizados.find(
        (parametro) => parametro.nome_api === "cpf"
      );

      if (!parametroCpf || !parametroCpf.Valor) return false;

      const cpfParametro = parametroCpf.Valor.replace(/\D/g, "");

      const ehFavorito = this.parametrosFavoritos.some((favorito) => {
        if (!favorito.cpf) this.cpfEhFavorito = false;
        const cpfFavorito = favorito.cpf.replace(/\D/g, "");
        return cpfFavorito.includes(cpfParametro);
      });

      this.cpfEhFavorito = ehFavorito;
    },

    validaConsultar() {
      if (this.parametrosUtilizados.length > 0) {
        const todosVazios = this.parametrosUtilizados.every(
          (parametro) => parametro.Valor === undefined
        );
        if (todosVazios) {
          this.liberaEnviar = false;
          return;
        }
        const obrigatorioInvalido = this.parametrosUtilizados.some(
          (parametro) => {
            return (
              parametro.Obrigatorio &&
              parametro.visivel &&
              parametro.Classe !== "md-valid"
            );
          }
        );

        const edicaoErro = this.parametrosUtilizados.some((parametro) => {
          const classe = parametro.Classe;
          return classe === "md-edit" || classe === "md-error";
        });

        this.liberaEnviar = !obrigatorioInvalido && !edicaoErro;
      }
    },

    selecionaData(context, parametro) {
      const dataBR = context.split("-").reverse().join("/");
      parametro.Valor = dataBR;

      this.liberaDatePicker[parametro.nome_api] = false;
      this.selecionaClasseInput(parametro);
    },

    filtrarDominio(parametro) {
      const valorDigitado = parametro.Valor;
      const itemDominio = parametro.dominio.find(
        (item) => item.valor === valorDigitado
      );
      if (parametro.Valor != "" && parametro.Valor != undefined) {
        if (itemDominio) {
          parametro.placeholderText = `${itemDominio.filial} - ${itemDominio.regiao}`;
        } else {
          parametro.placeholderText = "Filial não encontrada";
        }
      } else {
        parametro.placeholderText = "";
      }
      this.selecionaClasseInput(parametro);
    },

    // async recuperaDadosUsuario() {
    //   await this.recuperaUsuarioLogado();
    // },

    optionsListResposta(opcoes) {
      if (this.searchSelect.trim().length > 0) {
        return opcoes.filter((opcao) =>
          opcao.descricao
            .toLowerCase()
            .includes(this.searchSelect.trim().toLowerCase())
        );
      } else {
        return opcoes;
      }
    },

    clearSearch() {
      this.searchSelect = "";
    },

    decideConsulta() {
      const objetoResultado = {};

      if (this.liberaEnviar) {
        this.liberaSpinner = true;
        this.parametrosUtilizados.forEach((parametro) => {
          if (parametro.tipo_input == "data" || parametro.Tipo == "Data") {
            objetoResultado[parametro.nome_api] =
              parametro.ValorDatePicker !== undefined
                ? parametro.ValorDatePicker
                : this.formatDateYMD(parametro.Valor);
          } else {
            objetoResultado[parametro.nome_api] =
              parametro.Valor !== undefined ? parametro.Valor : "";
          }
        });

        for (var chave in objetoResultado) {
          if (
            objetoResultado[chave] != null &&
            objetoResultado[chave] != undefined
          ) {
            if (
              objetoResultado[chave].includes("R$") &&
              typeof objetoResultado[chave] == "string"
            ) {
              objetoResultado[chave] = objetoResultado[chave].replace("R$", "");
              objetoResultado[chave] = objetoResultado[chave].replace(
                /\./g,
                ""
              );
              objetoResultado[chave] = objetoResultado[chave].replace(",", ".");
              objetoResultado[chave] = objetoResultado[chave].replace(
                /\s/g,
                ""
              );
            } else if (
              objetoResultado[chave].includes(",") &&
              typeof objetoResultado[chave] == "string"
            ) {
              objetoResultado[chave] = objetoResultado[chave].replace(",", ".");
            }
          }
        }

        this.resultados = objetoResultado;

        this.$emit("realizaConsultaModelos", this.resultados);

        setTimeout(() => {
          this.liberaSpinner = false;
          this.alteraLiberaInput(false);
        }, 2000);
      }
    },

    async recuperarLayoutModelo() {
      this.layoutModelo = {};

      const objetoHistorico = {
        cod_ref: this.modeloSelecionado.Valor,
      };
      const query = new URLSearchParams(objetoHistorico);
      this.layoutModelo = await lotesService.recuperaLayoutModelo(
        query.toString()
      );

      this.verificaParametros();
    },

    verificaParametros() {
      const chaveExistente = Object.keys(this.listaInputs).includes(
        this.modeloSelecionado.Valor
      );

      let listaUtilizada;
      if (chaveExistente) {
        const listaSelecionada = this.listaInputs[this.modeloSelecionado.Valor];
        const listaPadrao = this.listaInputs["Padrao"];

        const nomesApiEncontrados = new Set();

        const listaPadraoFiltrada = listaPadrao.filter((obj) => {
          const nomeApi = obj.nome_api;
          const jaEncontrado = nomesApiEncontrados.has(nomeApi);

          if (!jaEncontrado) {
            nomesApiEncontrados.add(nomeApi);
            return true;
          }

          return false;
        });

        listaUtilizada = listaSelecionada.concat(listaPadraoFiltrada);
      } else {
        listaUtilizada = this.listaInputs["Padrao"];
      }

      let listaParametros = this.layoutModelo.ListaParametros;

      const index = listaParametros.findIndex(
        (item) => item.Identificador === "cpf_cnpj"
      );

      if (index === -1) {
        listaParametros.push({
          Identificador: "cpf_cnpj",
          Obrigatorio: true,
          Tipo: "Cpf",
        });
      }

      listaParametros.forEach((parametro) => {
        if (parametro.Identificador === "cpf_cnpj") {
          if (this.opcao_inicial === "Pessoa Física") {
            parametro.Identificador = "cpf";
            parametro.Obrigatorio = true;
          } else if (this.opcao_inicial === "Pessoa Jurídica") {
            parametro.Identificador = "cnpj";
            parametro.Obrigatorio = true;
            parametro.Tipo = "Cnpj";
          }
        }
      });

      const identificadoresAdicionados = new Set();

      for (const item of listaParametros) {
        const listaCorrespondente = listaUtilizada.find(
          (lista) => lista.nome_api === item.Identificador
        );

        if (listaCorrespondente) {
          const combinedObj = this.mesclarObjetos(listaCorrespondente, item);
          delete combinedObj.Identificador;

          if (!identificadoresAdicionados.has(combinedObj.nome_api)) {
            this.parametrosUtilizados.push(combinedObj);
            identificadoresAdicionados.add(combinedObj.nome_api);
          }
        } else {
          item.nome_api = item.Identificador;
          item.visivel = true;
          item.nome_amigavel = item.nome_api;
          delete item.Identificador;

          if (!identificadoresAdicionados.has(item.nome_api)) {
            this.parametrosUtilizados.push(item);
            identificadoresAdicionados.add(item.nome_api);
          }
        }
      }

      if (this.usuarioLogado.Empresa == "StepWise") {
        let objetoEncontrado = this.parametrosUtilizados.find(
          (obj) => obj.nome_api === "data_ref"
        );
        if (objetoEncontrado) {
          objetoEncontrado.visivel = false;
          objetoEncontrado.Obrigatorio = false;
        }
      }

      this.parametrosUtilizados.sort((a, b) => {
        if (a.Obrigatorio !== b.Obrigatorio) {
          return a.Obrigatorio ? -1 : 1;
        } else {
          if (a.nome_api < b.nome_api) {
            return -1;
          } else if (a.nome_api > b.nome_api) {
            return 1;
          } else {
            return 0;
          }
        }
      });

      this.parametrosUtilizados.forEach((parametro) => {
        if (parametro.tipo_input == "data") {
          this.liberaDatePicker[parametro.nome_api] == false;
        }
      });

      this.liberaSpinner = false;
    },

    mesclarObjetos(obj1, obj2) {
      const resultado = {};
      for (const chave in obj1) {
        resultado[chave] = obj1[chave];
      }
      for (const chave in obj2) {
        resultado[chave] = obj2[chave];
      }
      return resultado;
    },

    aplicarMascara(parametro) {
      let formatted = "";
      let index = 0;

      if (parametro.Tipo == "Data") {
        parametro.mascara = "##/##/####";
        parametro.tamanho = 10;
      }

      if (parametro.Valor != undefined) {
        if (parametro.Valor.trim() !== "") {
          if (
            parametro.Tipo === "Cpf" ||
            parametro.Tipo == "Cnpj" ||
            parametro.Tipo == "Data" ||
            parametro.Tipo == "Cep"
          ) {
            if (parametro.mascara) {
              const mask = parametro.mascara;
              const inputValue = parametro.Valor.replace(/[^0-9]+/g, "");
              let result = inputValue.slice(0, parametro.tamanho);

              for (let i = 0; i < mask.length && index < result.length; i++) {
                const maskChar = mask[i];
                if (maskChar === "#") {
                  formatted += result[index] || "";
                  index++;
                } else {
                  formatted += maskChar;
                }
              }
            } else {
              formatted = parametro.Valor;
            }
          } else if (parametro.Tipo === "Texto") {
            formatted = parametro.Valor;
          } else if (parametro.Tipo === "Inteiro") {
            if (parametro.tipo_input == "numero") {
              formatted = parametro.Valor.replace(/[^\d,]/g, "");
            } else if (parametro.tipo_input == "numero-dominio") {
              formatted = parametro.Valor.replace(/[^\d,]/g, "");
            } else {
              formatted = parametro.Valor.replace(/[^\d,]/g, "");
            }
          } else if (parametro.Tipo === "Decimal") {
            if (parametro.tipo_input == "numero") {
              formatted = parametro.Valor.replace(/[^\d,]/g, "");
            } else if (parametro.tipo_input == "valor") {
              const valorNumerico =
                parseFloat(parametro.Valor.replace(/[^\d]/g, "")) / 100;

              const valorFormatado = valorNumerico.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              });

              formatted = valorFormatado;
            } else {
              formatted = parametro.Valor.replace(/[^\d,]/g, "");
            }
          } else {
            formatted = parametro.Valor;
          }
        }
      }
      // parametro.Valor = formatted;
      this.$set(parametro, "Valor", formatted);

      this.selecionaClasseInput(parametro);
      this.verificaDocumentoFavorito();

      return parametro;
    },

    selecionaClasseInput(parametro) {
      if (parametro.Valor != undefined) {
        if (parametro.Valor.trim() !== "") {
          if (
            parametro.Tipo === "Cpf" ||
            parametro.Tipo == "Cnpj" ||
            parametro.Tipo == "Data" ||
            parametro.Tipo == "Cep"
          ) {
            if (parametro.mascara) {
              if (parametro.Valor.length < parametro.tamanho) {
                parametro.Classe = "md-edit";
              } else if (parametro.Valor.length === parametro.tamanho) {
                if (
                  (parametro.nome_api == "cpf" &&
                    !validadorCpf.valida(parametro.Valor)) ||
                  (parametro.nome_api == "cnpj" &&
                    !validadorCnpj.valida(parametro.Valor)) ||
                  (parametro.Tipo == "Data" &&
                    (!validadorDataPtbr.valida(parametro.Valor) ||
                      new Date(this.formatDateYMD(parametro.Valor)) >
                        new Date()))
                ) {
                  parametro.Classe = "md-error";
                } else {
                  parametro.Classe = "md-valid";
                }
              }
            } else {
              if (
                (parametro.Tipo === "Cpf" &&
                  !validadorCpf.valida(parametro.Valor)) ||
                (parametro.Tipo == "Cnpj" &&
                  !validadorCnpj.valida(parametro.Valor)) ||
                (parametro.Tipo == "Data" &&
                  !validadorDataPtbr.valida(parametro.Valor))
              ) {
                parametro.Classe = "md-error";
              } else {
                parametro.Classe = "md-valid";
              }
            }
          } else if (parametro.Tipo === "Texto") {
            if ((parametro.tipo_input = "selecao")) {
              parametro.Classe = "md-valid";
            } else {
              if (parametro.tamanho) {
                if (parametro.Valor.length < parametro.tamanho) {
                  parametro.Classe = "md-edit";
                } else if (parametro.Valor.length > parametro.tamanho) {
                  parametro.Classe = "md-error";
                } else {
                  parametro.Classe = "md-valid";
                }
              } else {
                parametro.Classe = "md-valid";
              }
            }
          } else if (parametro.Tipo === "Inteiro") {
            if (parametro.tipo_input == "numero") {
              if (/^\d+$/.test(parametro.Valor)) {
                parametro.Classe = "md-valid";
              } else {
                parametro.Classe = "md-error";
              }
            } else if (parametro.tipo_input == "numero-dominio") {
              if (/^\d+$/.test(parametro.Valor)) {
                parametro.Classe = "md-valid";
              } else {
                parametro.Classe = "md-error";
              }
            } else {
              if (/^\d+$/.test(parametro.Valor)) {
                parametro.Classe = "md-valid";
              } else {
                parametro.Classe = "md-error";
              }
            }
          } else if (parametro.Tipo === "Decimal") {
            if (parametro.tipo_input == "numero") {
              if (/^\d+(,\d*)?$/.test(parametro.Valor)) {
                parametro.Classe = "md-valid";
              } else {
                parametro.Classe = "md-error";
              }
            } else if (parametro.tipo_input == "valor") {
              var valorSemEspaco = parametro.Valor.replace(/\s+/g, "");
              if (
                /^(R\$)?[0-9]+([,.][0-9]{3})*([,.][0-9]{2})?$/.test(
                  valorSemEspaco
                )
              ) {
                parametro.Classe = "md-valid";
              } else {
                parametro.Classe = "md-error";
              }
            } else {
              if (/^\d+(,\d*)?$/.test(parametro.Valor)) {
                parametro.Classe = "md-valid";
              } else {
                parametro.Classe = "md-error";
              }
            }
          } else {
            parametro.Classe = "md-valid";
          }
        } else {
          parametro.Classe = undefined;
        }
      }
    },

    abreEscolhaData(parametro) {
      if (this.liberaDatePicker[parametro.nome_api] == true) {
        this.$set(this.liberaDatePicker, parametro.nome_api, false);
      } else {
        this.$set(this.liberaDatePicker, parametro.nome_api, true);
      }
    },

    filtrarTeclado(event, parametro) {
      const keyCode = event.key;
      const ctrlKey = event.ctrlKey;

      if (parametro.Tipo == "Inteiro") {
        if (/^[0-9]+$/.test(keyCode) || keyCode.length > 2 || ctrlKey) {
          return;
        } else {
          event.preventDefault();
        }
      }

      if (parametro.Tipo == "Decimal") {
        if (
          /^[0-9]+$/.test(keyCode) ||
          keyCode.length > 2 ||
          (/^(\d+|\,)$/.test(keyCode) && !parametro.Valor.includes(",")) ||
          ctrlKey
        ) {
          return;
        } else {
          event.preventDefault();
        }
      }
      if (parametro.tipo_input == "valor") {
        if (/^[0-9]+$/.test(keyCode) || keyCode.length > 2 || ctrlKey) {
          return;
        } else {
          event.preventDefault();
        }
      }

      this.verificaDocumentoFavorito();
    },

    formatDateYMD(input) {
      if (input instanceof Date) {
        const year = input.getFullYear();
        const month = String(input.getMonth() + 1).padStart(2, "0");
        const day = String(input.getDate()).padStart(2, "0");
        return `${year}-${month}-${day}`;
      } else if (typeof input === "string") {
        const parts = input.split("/");
        if (parts.length === 3) {
          const year = parts[2];
          const month = String(parts[1]).padStart(2, "0");
          const day = String(parts[0]).padStart(2, "0");
          return `${year}-${month}-${day}`;
        } else {
          return null;
        }
      } else {
        return null;
      }
    },

    verificaDocumento(str) {
      const cleanedStr = str.replace(/[^\d]/g, "");
      if (cleanedStr.length === 11) {
        return "CPF";
      }
      if (cleanedStr.length === 14 && str.includes("/") && str.includes("-")) {
        return "CNPJ";
      }
      if (cleanedStr.length === 8 && str.includes("-")) {
        return "CEP";
      }
      return "Descrição";
    },

    irPreferencias() {
      this.$router.push("/preferencias");
    },

    async syncAndSetParametrosSugeridos(key, targetProperty, opcao) {
      //recupera parametros sugeridos
      const preferencia = await this.syncPreferenciaGeral(
        `PortalOutbox_${key}`
      );

      if (preferencia === undefined) {
        await this.createPreferencia(`PortalOutbox_${key}`);
      } else {
        //adiciona parametros à tela
        const valorGet = preferencia.Valor;
        if (valorGet != null) {
          if (typeof this.parametrosSugeridos[targetProperty] === "undefined") {
            this.parametrosSugeridos[targetProperty] = [];
          }
          const objetos = valorGet.split("; ").map((item) => {
            const valores = item.split(", ");
            const objeto = {};
            for (let i = 0; i < valores.length; i++) {
              if (!/^ *$/.test(valores[i])) {
                objeto[this.verificaDocumento(valores[i])] = valores[i];
              }
            }
            return objeto;
          });

          const novoObjetos = objetos.filter((objeto) => {
            return Object.keys(objeto).length !== 0;
          });

          this.parametrosSugeridos[targetProperty] = novoObjetos;

          if (this.opcao_inicial === opcao) {
            this.imprimeParametro = this.parametrosSugeridos[targetProperty];
          }
        }
      }
    },

    ...mapActions(userPreferenceStore, [
      "recuperaUsuarioLogado",
      "createPreferencia",
      "substituiPreferencia",
      "syncPreferenciaGeral",
      "syncPreferenciaExibeParametros",
      "syncPreferenciaOcultarDados",
    ]),

    ...mapActions(dadosConsultaAddStore, ["resetConsutaAdd"]),
  },

  computed: {
    parametrosFavoritos() {
      return favoritosCases;
    },

    imprimeParametroFiltro() {
      if (this.ocultarDados) {
        var dadospf = this.imprimeParametro.map((objeto) => {
          return { ...objeto };
        });

        return dadospf.map((objeto) => {
          if (objeto.CPF) {
            objeto.CPF = objeto.CPF.replace(
              /(\d{3})\.(\d{3})\.(\d{3})-(\d{2})/,
              "***.$2.$3-**"
            );
          }
          return objeto;
        });
      } else {
        return this.imprimeParametro;
      }
    },

    listaInputs() {
      return inputsModelos;
    },

    ...mapWritableState(chatOutboxStore, {
      cpfEhFavorito: "cpfEhFavorito",
    }),

    ...mapWritableState(userPreferenceStore, {
      usuarioLogado: "usuarioLogado",
      parametrosSugeridos: "parametrosSugeridos",
      exibirFavoritos: "exibirFavoritos",
      ocultarDados: "ocultarDados",
    }),
  },

  async mounted() {
    this.liberaSpinner = true;
    if (Object.keys(this.modeloSelecionado).length != 0) {
      //   this.$router.push("/consultas/modelos/0");
      //   window.location.reload();
      // } else {
      // this.recuperaDadosUsuario();
      await this.recuperarLayoutModelo();
    }

    // this.exibirFavoritos = await this.syncPreferenciaExibeParametros();
    // this.ocultarDados = await this.syncPreferenciaOcultarDados();

    // await this.syncAndSetParametrosSugeridos(
    //   "parametrosSugeridosPf",
    //   "pf",
    //   "Pessoa Física"
    // );
    // await this.syncAndSetParametrosSugeridos(
    //   "parametrosSugeridosPj",
    //   "pj",
    //   "Pessoa Jurídica"
    // );

    this.liberaSpinner = false;
  },
};
</script>

<style lang="scss">
.no-counter {
  .md-count {
    display: none !important;
  }
}
</style>

<style scoped lang="scss">
.container {
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0;
}

.campo-box {
  display: flex;
  justify-content: center;
}

.campos {
  gap: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  margin: 20px 0 0 0;
}

.box-inputs {
  width: 90%;
  margin: 0;
}

.selecao {
  gap: 10px;
  width: 20vw;
  line-height: 20px;

  .titulo {
    font-size: 20px;
    font-weight: 600;
    margin: 20px 0;
  }
  .valor {
    font-size: 16px;
    font-weight: 400;
    display: flex;
    gap: 10px;
  }
  .descricao {
    font-size: 12px;
    font-weight: 300;
  }
}

.item {
  display: flex;
  gap: 10px;
  flex-direction: column;
}

.textSearch {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-bottom: 0.3rem;
  margin-top: 0.3rem;
}

.textSearchInput {
  display: flex;
  width: 95%;
  height: 35px;
  border-width: 0px 0px 0.1rem 0px;
  font-size: small;
}

.placeholder-text {
  position: absolute;
  font-size: 12px;
  right: 30px;
  top: 10px;
  color: gray;
}

.loading {
  position: absolute;
  top: 15%;
  right: 40%;
  z-index: 10;
}

.date-btn {
  border: 1px solid transparent;
  background: transparent;
  cursor: pointer;
  margin: 0;
  border-radius: 25%;
  padding: 5px 0 0 0;

  &:hover {
    transform: scale(1.05);
  }

  &:active {
    transform: scale(0.95);
  }
}
.calendar {
  color: grey;
  cursor: pointer;
  margin: 5px;
}

.box-calendar {
  position: absolute;
  left: 100%;
  z-index: 11;
  background-color: white;
  padding: 5px;
}

.favoritos {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 320px;
  margin: 0;
  position: absolute;
  top: 15px;
  right: 15px;

  .card-fav {
    background: transparent;
    flex-direction: row;
  }
  .card-header {
    width: fit-content;
    border: none;
    box-shadow: none;
    max-height: 43px;
  }
  .content-fav {
    width: 100%;
    display: flex;
  }
  .selectFav {
    margin: 0 10px 0 0;
    width: 100%;
  }
  .star {
    margin: 0 5px 0px 0;
    color: #2196f3;
    opacity: 0.4;
    font-size: 18px;
    cursor: default;

    &.vazio {
      color: gray;
    }
  }
}

.botao-acao {
  background-color: transparent;
  border: none;
  padding: 5px 10px;
  color: white;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  transition: 0.3s;
  background-image: url(/img/tela-inicial/Botao-Nova.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  text-transform: uppercase;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 140px;
  height: 40px;

  &:hover {
    transform: scale(1.05);
  }
  &:active {
    transform: scale(0.95);
  }

  &.disabled {
    background-image: url(/img/tela-inicial/Botao-Nova-cinza.png);
    pointer-events: none;
    opacity: 0.7;
  }
}

.btn-center {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
</style>

<style>
.md-field.md-has-password .md-toggle-password {
  background-color: transparent !important;
  box-shadow: none;
}
</style>
