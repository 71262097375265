<template>
  <div class="container">
    <table v-if="paginatedData.length > 0">
      <thead>
        <tr>
          <th
            v-for="col in headerStatus"
            :key="col"
            class="cabecalho"
            style="cursor: pointer"
            @click="sortColumn(col)"
          >
            {{ col }}
            <span class="sort-arrow" :class="{ active: sortedColumn === col }">
              {{ sortedColumn === col && sortDirection === "asc" ? "▲" : "▼" }}
            </span>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(row, index) in paginatedData" :key="index" class="linhas">
          <td
            v-for="(value, key) in row"
            :key="key"
            class="cell"
            :class="{ total: row.Grupo == 'Total' }"
          >
            {{ value }}
            <span v-if="hasHistorico && row.Grupo != 'Total'">
              <span
                v-if="
                  getComparison(row.Grupo, key).direction === 'up' &&
                  (key == 'Media_Churn' || key == 'Media_Inad')
                "
                class="seta-cima legenda"
              >
                ↑ +{{ getComparison(row.Grupo, key).difference }}
              </span>
              <span
                v-else-if="
                  getComparison(row.Grupo, key).direction === 'down' &&
                  (key == 'Media_Churn' || key == 'Media_Inad')
                "
                class="seta-baixo legenda"
              >
                ↓ -{{ getComparison(row.Grupo, key).difference }}
              </span>
              <span
                v-else-if="key == 'Media_Churn' || key == 'Media_Inad'"
                class="seta-direita legenda"
              >
                → 0
              </span>
            </span>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="aviso" v-else>Nenhuma cota adicionada</div>
    <div v-if="valoresTabelaStatus.length > rowsPerPage" class="pagination">
      <span
        class="material-symbols-outlined seta-pagina"
        :class="{ disabled: currentPage === 1 }"
        @click="previousPage"
      >
        keyboard_double_arrow_left
      </span>
      <span class="texto-pagina"
        >Página {{ currentPage }} de {{ totalPages }}</span
      >
      <span
        class="material-symbols-outlined seta-pagina"
        :class="{ disabled: currentPage === totalPages }"
        @click="nextPage"
      >
        keyboard_double_arrow_right
      </span>
    </div>
  </div>
</template>

<script>
import Loading from "@/components/Loading.vue";
import inputsModelos from "@/helpers/inputs_modelos.json";

import { mapWritableState, mapActions } from "pinia";
import { userPreferenceStore } from "@/stores/userPreference";
import { dadosConsultaAddStore } from "@/stores/dadosConsultaAdd";
import { lotesService } from "@/services";

export default {
  name: "tabelaStatusConsorcios",

  props: {
    valoresTabelaStatus: Array,
    historicoValoresSumario: Array,
  },

  data() {
    return {
      headerStatus: ["Grupo", "Qtd Cotas", "Média Churn", "Média Inad."],

      keyMap: {
        Grupo: "Grupo",
        "Qtd Cotas": "Qtd_Cotas",
        "Média Churn": "Media_Churn",
        "Média Inad.": "Media_Inad",
      },

      currentPage: 1,
      rowsPerPage: 5,
      sortedColumn: "",
      sortDirection: "",
    };
  },

  components: {},

  methods: {
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },

    sortColumn(col) {
      if (this.sortedColumn === col) {
        if (this.sortDirection === "asc") {
          this.sortDirection = "desc";
        } else if (this.sortDirection === "desc") {
          this.sortedColumn = null;
          this.sortDirection = null;
        } else {
          this.sortDirection = "asc";
        }
      } else {
        this.sortedColumn = col;
        this.sortDirection = "asc";
      }
    },

    getComparison(grupo, key) {
      if (!this.hasHistorico) {
        return { direction: "right", difference: 0 };
      }
      const historicoRow = this.historicoValoresSumario.find(
        (row) => row.Grupo === grupo
      );
      if (!historicoRow) {
        return { direction: "right", difference: 0 };
      }
      const oldValue = historicoRow[key];
      const newValue = this.valoresTabelaStatus.find(
        (row) => row.Grupo === grupo
      )[key];
      const difference = newValue - oldValue;

      if (difference > 0) {
        return { direction: "up", difference };
      } else if (difference < 0) {
        return { direction: "down", difference: Math.abs(difference) };
      } else {
        return { direction: "right", difference: 0 };
      }
    },

    ...mapActions(userPreferenceStore, [
      "recuperaUsuarioLogado",
      "createPreferencia",
      "substituiPreferencia",
      "syncPreferenciaGeral",
      "syncPreferenciaExibeParametros",
      "syncPreferenciaOcultarDados",
    ]),

    ...mapActions(dadosConsultaAddStore, ["resetConsutaAdd"]),
  },

  computed: {
    hasHistorico() {
      return this.historicoValoresSumario.length > 0;
    },

    paginatedData() {
      const start = (this.currentPage - 1) * this.rowsPerPage;
      const end = start + this.rowsPerPage;
      return this.sortedData.slice(start, end);
    },

    sortedData() {
      if (!this.sortedColumn) {
        return [...this.valoresTabelaStatus].sort((a, b) => {
          if (a.Grupo === "Total") return 1;
          if (b.Grupo === "Total") return -1;
          let valA = a["Grupo"];
          let valB = b["Grupo"];
          valA = String(valA || "").toLowerCase();
          valB = String(valB || "").toLowerCase();
          return valA.localeCompare(valB);
        });
      }

      var columnKey = this.keyMap[this.sortedColumn];

      return [...this.valoresTabelaStatus].sort((a, b) => {
        let valA = a[columnKey];
        let valB = b[columnKey];

        if (a.Grupo === "Total") return 1;
        if (b.Grupo === "Total") return -1;

        switch (this.sortedColumn) {
          case "Grupo":
            valA = String(valA || "").toLowerCase();
            valB = String(valB || "").toLowerCase();
            return this.sortDirection === "asc"
              ? valA.localeCompare(valB)
              : valB.localeCompare(valA);

          case "Qtd Cotas":
            valA = parseFloat(valA) || 0;
            valB = parseFloat(valB) || 0;
            return this.sortDirection === "asc" ? valA - valB : valB - valA;

          case "Média Churn":
          case "Média Inad.":
            valA =
              parseFloat(
                String(valA || "")
                  .trim()
                  .replace(",", ".")
              ) || 0;
            valB =
              parseFloat(
                String(valB || "")
                  .trim()
                  .replace(",", ".")
              ) || 0;
            return this.sortDirection === "asc" ? valA - valB : valB - valA;

          default:
            return 0;
        }
      });
    },

    totalPages() {
      return Math.ceil(this.valoresTabelaStatus.length / this.rowsPerPage);
    },

    ...mapWritableState(userPreferenceStore, {
      usuarioLogado: "usuarioLogado",
      parametrosSugeridos: "parametrosSugeridos",
      exibirFavoritos: "exibirFavoritos",
      ocultarDados: "ocultarDados",
    }),
  },

  async mounted() {},
};
</script>

<style scoped lang="scss">
.container {
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0;
}

.cabecalho {
  font-size: 14px;
  font-weight: 400;
  width: calc(100% / 6);
  text-align: center;
  height: 25px;
}

.linhas {
  background-color: #ececec;
  border-bottom: 1px solid gray;
  height: 30px;
  cursor: default;

  &:hover {
    background-color: #e0e0e0;
  }

  &.nova-linha {
    background-color: #fffcbe;
    &:hover {
      background-color: #efeca2;
    }
  }
}

.sort-arrow {
  margin-left: 5px;
  opacity: 0.5;
}

.sort-arrow.active {
  opacity: 1;
}

.pagination {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.texto-pagina {
  font-size: 12px;
  font-weight: 300;
  line-height: 14px;
}

.seta-pagina {
  margin: 0px;
  padding: 5px;
  cursor: pointer;
  color: #1da0f2;
  user-select: none;
}

.disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.cell {
  font-size: 14px;
  font-weight: 300;
  text-align: center;

  &.total {
    font-weight: 500;
  }
}

.aviso {
  font-size: 14px;
  font-weight: 300;
  text-align: center;
}

.legenda {
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
}
.seta-cima {
  color: green;
}
.seta-baixo {
  color: red;
}
.seta-direita {
  color: gray;
}
</style>
