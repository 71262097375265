<template>
  <div class="container-consulta">
    <div class="card card-consulta" v-if="mostrarConsulta">
      <div class="titulo-box">
        <div class="titulo">Nova Cota Pessoa Física</div>
      </div>
      <preenchimentoCampos
        v-if="mostrarConsulta"
        :opcao_inicial="opcaoInicial"
        :modeloSelecionado="modeloSelecionado"
        :liberaInputs="liberaInputs"
        @realizaConsultaModelos="realizaConsultaModelos"
        @retornaGrupos="retornaGrupos"
        @alteraLiberaInput="alteraLiberaInput"
      />
    </div>
    <div class="card card-consulta" v-if="mostrarConsulta">
      <div class="titulo-box">
        <div class="titulo">Resultados</div>
        <span
          class="botao-cancelar"
          v-if="
            liberaInputs && Object.keys(this.resultadoConsultaAdd).length != 0
          "
        >
          <button class="botao-acao cancelar" @click="liberaInputs = false">
            Cancelar alteração
          </button>
        </span>
      </div>
      <div
        class="resultados-box"
        :class="{ alterando: liberaInputs }"
        v-if="Object.keys(this.resultadoConsultaAdd).length != 0"
      >
        <div class="item">
          <div class="item-titulo">
            <span class="item-descricao">Nome Completo: </span>
            <span class="item-descricao valor">
              {{ formatarNome(resultadoConsultaAdd.bk_101_001_nome) }}
            </span>
          </div>
          <div class="item-titulo">
            <span class="item-descricao">Idade: </span>
            <span class="item-descricao valor">
              {{ resultadoConsultaAdd.idade_calculada }}
            </span>
          </div>
        </div>
      </div>
      <div
        class="resultados-box"
        :class="{ alterando: liberaInputs }"
        v-if="Object.keys(this.resultadoModelo).length != 0"
      >
        <div class="item">
          <div class="item-titulo">
            <span class="titulo">BOXPSCON003: </span>
            <span class="valor">{{ resultadoPS }}</span>
          </div>
          <div class="item-descricao">
            Score de propensão de cancelamento PF
          </div>
        </div>
        <div class="item">
          <div class="item-titulo">
            <span class="titulo">BOXCSCON003: </span>
            <span class="valor"> {{ resultadoCS }}</span>
          </div>
          <div class="item-descricao">
            Score de inadimplência em consórcio PF
          </div>
        </div>
        <div class="item">
          <button
            class="botao-acao"
            @click="enviarNovaCota()"
            :disabled="liberaInputs"
            :class="{
              disabled: liberaInputs,
            }"
          >
            Incluir
          </button>
        </div>
      </div>
      <div v-else>
        <div class="item-descricao">
          Realize uma consulta de uma nova cota para continuar
        </div>
      </div>
      <div class="aviso" v-if="msgErro != ''">
        Ops, parece que ocorreu um erro: <br />
        {{ msgErro }}
      </div>
    </div>
    <div class="card card-consulta" v-if="!mostrarConsulta">
      <div class="titulo-box">
        <div class="titulo">Cotas Ativas</div>
        <div class="item">
          <md-field class="field-pesquisar">
            <label
              for="pesquisaProdutos"
              style="padding: 0 5px; margin: 6px 0px"
            >
              Pesquisar
            </label>
            <md-input id="pesquisaProdutos" v-model="textoPesquisa"></md-input>
          </md-field>
        </div>
        <div class="item">
          <button
            class="botao-acao"
            @click="liberarNovaConsulta()"
            :disabled="carregandoTabela"
          >
            Nova cota
          </button>
        </div>
        <span
          class="material-symbols-outlined icone-reset"
          @click="resetTabelaGrupos()"
          id="icone-resetar"
        >
          restart_alt
        </span>
        <b-tooltip
          :target="'icone-resetar'"
          triggers="hover"
          placement="bottom"
          variant="secondary"
          class="tooltip-projeto"
          boundary="window"
        >
          Reset da tabela
        </b-tooltip>
      </div>
      <tabelaGruposConsorcios
        ref="tabelaGruposConsorcios"
        v-if="!carregandoTabela"
        :valoresTabelaGrupos="valoresTabelaGruposFiltrada"
        :historicoNovasCotas="historicoNovasCotas"
      />
      <div v-else>
        <Loading class="loading" />
      </div>
    </div>
    <div class="card card-consulta" v-if="!mostrarConsulta">
      <div class="titulo-box">
        <div class="titulo">Grupos Ativos</div>
      </div>
      <tabelaStatusConsorcios
        v-if="!carregandoTabela"
        :valoresTabelaStatus="valoresTabelaStatus"
        :historicoValoresSumario="historicoValoresSumario"
      />
      <div v-else>
        <Loading class="loading" />
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "@/components/Loading.vue";
import { chatService } from "@/services";
import { consultasService } from "@/services";
import { chatOutboxStore } from "@/stores/chat-Outbox.js";
import { userPreferenceStore } from "@/stores/userPreference";
import { produtosStore } from "@/stores/produtos";
import { mapWritableState, mapActions } from "pinia";
import inputsModelos from "@/helpers/inputs_modelos.json";
import preenchimentoCampos from "@/components/AssistenteSingu/preenchimentoCampos.vue";
import tabelaGruposConsorcios from "@/components/AssistenteSingu/tabelaGruposConsorcios.vue";
import tabelaStatusConsorcios from "@/components/AssistenteSingu/tabelaStatusConsorcios.vue";

export default {
  name: "SaidaGestorConsorcio",

  components: {
    preenchimentoCampos,
    tabelaGruposConsorcios,
    tabelaStatusConsorcios,
    Loading,
  },

  props: {
    historicoChat: Array,
    entradaChat: String,
    thread_id: String,
    imagemAtual: String,
  },

  data() {
    return {
      modeloSelecionado: {
        Valor: "BOXPSCON003",
      },
      opcaoInicial: "Pessoa Física",

      modelosConsorcio: ["BOXCSCON003", "BOXPSCON003"],

      resultadoModelo: {},
      resultadoConsultaAdd: {},
      resultadoConsultaGeo: {},
      resultadoPS: "",
      resultadoCS: "",
      documento: "",
      valoresParametro: "",
      valoresParametrosRequisição: {},

      msgErro: "",
      dataFormatada: "",
      horaFormatada: "",
      textoPesquisa: "",

      mostrarConsulta: false,
      liberaInputs: true,
      carregandoTabela: false,

      historicoNovasCotas: [],
      valoresTabelaGrupos: [],
      valoresTabelaStatus: [],
      historicoValoresSumario: [],

      resultadoTabela: {},
      resultadoSumario: {},

      tabelaGrupos:
        "/docs/Bases/Base_Simulada_com_Variabilidade_sem_Grupo_3.csv",
    };
  },

  watch: {},

  methods: {
    reset() {
      this.resultadoModelo = {};
      this.resultadoConsultaAdd = {};
      this.resultadoConsultaGeo = {};
      this.resultadoPS = "";
      this.resultadoCS = "";
      this.msgErro = "";
      this.valoresParametro = "";
    },

    alteraLiberaInput(valor) {
      this.liberaInputs = valor;
      this.$emit("stopSimulacao");
    },

    retornaGrupos() {
      this.mostrarConsulta = false;
      this.reset();
    },

    liberarNovaConsulta() {
      this.$emit("stopSimulacao");
      this.mostrarConsulta = true;
      this.liberaInputs = true;
    },

    atualizarValorDocumento() {
      this.documento = this.resultadoModelo.sumario_consulta.parametros.cpf;
      this.documento = this.documento;
    },

    async resetTabelaGrupos() {
      this.carregandoTabela = true;

      await chatService.restauraTabelaGruposConsorcio();

      this.recuperaTabelas();
      this.carregandoTabela = false;
    },

    async enviarNovaCota() {
      this.mostrarConsulta = false;
      this.carregandoTabela = true;

      var str =
        this.resultadoConsultaAdd && this.resultadoConsultaAdd.bk_101_001_nome
          ? this.resultadoConsultaAdd.bk_101_001_nome
          : "";

      var uf =
        this.resultadoConsultaGeo &&
        this.resultadoConsultaGeo.bk_301_001_aglomerado_uf
          ? this.resultadoConsultaGeo.bk_301_001_aglomerado_uf
          : "";
      var municipio =
        this.resultadoConsultaGeo &&
        this.resultadoConsultaGeo.bk_301_001_municipio
          ? this.resultadoConsultaGeo.bk_301_001_municipio
          : "";

      var nomes = str.split(/\s+/);
      var primeiro_nome = nomes[0].replace(/[^\w\s]|_/g, "");
      primeiro_nome =
        primeiro_nome.charAt(0).toUpperCase() +
        primeiro_nome.slice(1).toLowerCase();

      this.atualizarValorDocumento();
      this.atualizaHora();
      this.$emit("stopSimulacao");

      const novaCota = {
        cep: this.valoresParametrosRequisição.cep,
        cpf: this.valoresParametrosRequisição.cpf.replace(/\D/g, ""),
        status: this.valoresParametrosRequisição.status,
        tipo_produto: this.valoresParametrosRequisição.tipo_produto,
        tipo_compra: this.valoresParametrosRequisição.tipo_compra,
        dth_inicio: this.valoresParametrosRequisição.dth_inicio,
        vlr_bem_venda: this.valoresParametrosRequisição.vlr_bem_venda,
        qtd_parcelas_prazo_cota:
          this.valoresParametrosRequisição.qtd_parcelas_prazo_cota,
        score_final_churn: this.resultadoPS,
        score_final_inad: this.resultadoCS,
        uf: uf,
        municipio: municipio,
        primeiro_nome: primeiro_nome,
      };

      await this.requisicaoAtribuiGrupo(novaCota);

      if (Object.keys(this.resultadoRequisiçãoAtribuiGrupo).length > 0) {
        const texto = this.resultadoRequisiçãoAtribuiGrupo.Mensagem;
        const imagem = this.resultadoRequisiçãoAtribuiGrupo.Imagem;
        this.$emit("enviarImagemSingu", texto, imagem);

        this.resultadoTabela = this.resultadoRequisiçãoAtribuiGrupo.Tabela;
        this.resultadoSumario = this.resultadoRequisiçãoAtribuiGrupo.Sumario;
      }

      await this.carregarTabela();

      const ids = this.valoresTabelaGrupos.map((cota) => cota.Id_Atribuicao);
      const novoId = Math.max(...ids);
      novaCota.Id_Atribuicao = novoId;
      this.historicoNovasCotas.unshift(novaCota);

      this.carregandoTabela = false;

      this.reset();
    },

    formatarCPF(cpfNumber) {
      var cpf = String(cpfNumber);
      if (cpf && cpf !== "-") {
        cpf = cpf.replace(/[^0-9*]/g, "");

        cpf = cpf.replace(
          /^(\d{0,3}|\*{0,3})(\d{0,3}|\*{0,3})(\d{0,3}|\*{0,3})(\d{0,2}|\*{0,2})$/,
          function (_, p1, p2, p3, p4) {
            return `${p1}.${p2}.${p3}-${p4}`;
          }
        );
      }
      return cpf;
    },

    ocultarCPF(cpfNumber) {
      var cpf = String(cpfNumber);
      if (cpf && cpf !== "-") {
        cpf = cpf.replace(/[^0-9*]/g, "");

        cpf = cpf.replace(
          /^(\d{0,3}|\*{0,3})(\d{0,3}|\*{0,3})(\d{0,3}|\*{0,3})(\d{0,2}|\*{0,2})$/,
          function (_, p1, p2, p3, p4) {
            return `${p1}.${p2}.${p3}-${p4}`;
          }
        );

        if (this.cpfEhFavorito) {
          return cpf.replace(
            /(\d{3}|\*{3})\.(\d{3}|\*{3})\.(\d{3}|\*{3})-(\d{2}|\*{2})/,
            "***.$2.$3-**"
          );
        } else {
          return cpf;
        }
      }
    },

    atualizaHora() {
      const agora = new Date();
      this.dataFormatada = "";
      this.dataFormatada = agora.toLocaleDateString("pt-BR");
      this.horaFormatada = "";
      this.horaFormatada = agora.toLocaleTimeString("pt-BR", {
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      });
    },

    recuperaParametros(resultado) {
      const valores = Object.entries(resultado)
        .filter(([chave, valor]) => {
          return (
            chave !== "cpf" &&
            chave !== "cnpj" &&
            valor !== null &&
            valor !== undefined &&
            valor !== ""
          );
        })
        .map(([chave, valor]) => {
          const input = this.listaInputs.BOXPSCON003.find(
            (item) => item.nome_api === chave
          );
          const nomeAmigavel = input ? input.nome_amigavel : chave;
          return `${nomeAmigavel}: ${valor}`;
        });

      this.valoresParametro = valores.join("; ");
    },

    async realizaConsultaModelos(resultado) {
      this.valoresParametrosRequisição = resultado;
      this.terminouConsulta = false;
      this.reset();
      this.recuperaParametros(resultado);

      for (const i in this.modelosConsorcio) {
        await this.geraCodigoAgrupadorMetadados("consulta_modelo");

        const mapeamento = {
          "Pessoa Física": "pessoa_fisica",
          "Pessoa Jurídica": "pessoa_juridica",
        };
        let tipoPessoa = mapeamento[this.opcaoInicial];

        const objetoConsulta = {
          cep: "",
          tipo_pessoa: tipoPessoa,
          cpf_cnpj: "",
          cod_ref: this.modelosConsorcio[i],
          data_ref: resultado.data_ref || "",
          modo_resultado: "codigo",
          parametros_adicionais: {},
          identificador_etiqueta_primaria: "PRD",
          identificadores_etiquetas_secundarias: "",
          cod_agrupador: this.codigo_agrupador,
        };

        for (const chave in resultado) {
          if (chave === "cpf" || chave === "cnpj") {
            objetoConsulta["cpf_cnpj"] = resultado[chave];
          } else {
            if (objetoConsulta.hasOwnProperty(chave)) {
              objetoConsulta[chave] = resultado[chave]
                ? resultado[chave].trim()
                : resultado[chave];
            } else {
              if (this.modelosConsorcio[i] === "BOXCSCON003") {
                if (chave === "tipo_produto") {
                  objetoConsulta.parametros_adicionais["tipo_produto_novo"] =
                    resultado[chave]
                      ? resultado[chave].trim()
                      : resultado[chave];
                } else if (
                  chave !== "dth_inicio" &&
                  chave != "vlr_parcela" &&
                  chave != "taxa_global_churn"
                ) {
                  objetoConsulta.parametros_adicionais[chave] = resultado[chave]
                    ? resultado[chave].trim()
                    : resultado[chave];
                }
              } else {
                objetoConsulta.parametros_adicionais[chave] = resultado[chave]
                  ? resultado[chave].trim()
                  : resultado[chave];
              }
            }
          }

          if (chave == "data_ref") {
            this.dataDaConsulta = resultado[chave];
          }
        }

        await consultasService
          .realizaConsultaModelo(objetoConsulta)
          .then((resultadoModelo) => {
            this.resultadoModelo = resultadoModelo;
          })
          .catch((erro) => {
            this.msgErro = erro;
            const texto =
              "Ops, parece que ocorreu um erro: <br/><br/>" + this.msgErro;
            this.$emit("enviarMensagemSingu", texto);
          });

        if (this.resultadoModelo.erro) {
          this.msgErro = this.resultadoModelo.erro.erro;
          const texto =
            "Ops, parece que ocorreu um erro: <br/><br/>" + this.msgErro;
          this.$emit("enviarMensagemSingu", texto);
        }

        if (this.modelosConsorcio[i] === "BOXCSCON003") {
          this.resultadoCS = this.resultadoModelo.atributos.score;
        }
        if (this.modelosConsorcio[i] === "BOXPSCON003") {
          this.resultadoPS = this.resultadoModelo.atributos.score;
        }
      }

      await this.realizaConsultaAdicional(resultado);

      this.terminouConsulta = true;
    },

    formatarNome(nome) {
      if (nome && nome != "-") {
        if (this.cpfEhFavorito) {
          let palavras = nome.split(" ");

          if (palavras.length > 1) {
            for (let i = 1; i < palavras.length; i++) {
              palavras[i] = palavras[i].replace(/\S/g, "*");
            }
          }
          return palavras.join(" ");
        } else {
          return nome;
        }
      }
      return nome;
    },

    enviarMensagemConsultaSingu() {
      this.atualizarValorDocumento();

      const texto1 =
        "Foi realizada um consulta do documento: <strong>" +
        this.ocultarCPF(this.documento) +
        "</strong>.";

      const texto2 =
        "<br/><br/>" +
        "<strong>Nome completo: </strong>" +
        this.formatarNome(this.resultadoConsultaAdd.bk_101_001_nome) +
        "<br/>" +
        "<strong>Idade: </strong>" +
        this.resultadoConsultaAdd.idade_calculada;

      const texto3 =
        "<br/><br/>" +
        "<strong>BOXPSCON003: </strong>" +
        this.resultadoPS +
        "<br/>" +
        "Score de propensão de cancelamento PF" +
        "<br/><br/>" +
        "<strong>BOXCSCON003: </strong>" +
        this.resultadoCS +
        "<br/>" +
        "Score de inadimplência em consórcio PF";

      if (this.valoresParametro != "") {
        const texto4 =
          "<br/><br/>" +
          "<strong>Parâmetros da consulta: </strong>" +
          "<br/>" +
          this.valoresParametro.replace(/;/g, ";<br/>");

        var texto = [texto1, texto4, texto2, texto3].join("\n");
      } else {
        var texto = [texto1, texto2, texto3].join("\n");
      }

      this.$emit("enviarMensagemSingu", texto);

    },

    async realizaConsultaAdicional(resultado) {
      const mapeamento = {
        "Pessoa Física": "pessoa_fisica",
        "Pessoa Jurídica": "pessoa_juridica",
      };

      let tipoPessoa = mapeamento[this.opcaoInicial];
      const consultasAdicionais = ["BOXSUPPF001", "BOXATRGEO001"];

      for (const i in consultasAdicionais) {
        var objetoConsulta = {};
        objetoConsulta["cpf_cnpj"] =
          tipoPessoa == "pessoa_fisica" ? resultado.cpf : resultado.cnpj;
        objetoConsulta["cep"] = resultado.cep;
        objetoConsulta["cod_ref"] = consultasAdicionais[i];
        objetoConsulta["tipo_pessoa"] = tipoPessoa;
        objetoConsulta["modo_resultado"] = "codigo";
        objetoConsulta["cod_agrupador"] = this.codigo_agrupador;
        objetoConsulta["data_ref"] = resultado.data_ref || "";

        await consultasService
          .realizaConsultaBox(objetoConsulta)
          .then((resultado) => {
            if (consultasAdicionais[i] == "BOXSUPPF001") {
              this.resultadoConsultaAdd = resultado.atributos;
            }
            if (consultasAdicionais[i] == "BOXATRGEO001") {
              this.resultadoConsultaGeo = resultado.atributos;
            }
          });
      }

      this.enviarMensagemConsultaSingu();
    },

    async carregarTabela() {
      const dicionario = {
        Grupo: "Grupo",
        CPF: "documento",
        Nome: "primeiro_nome",
        Data_Entrada: "dth_inicio_tratada",
        Hora: "Hora",
        Score_Churn: "score_final_churn",
        Score_Inad: "score_final_inad",
        Id_Atribuicao: "Id_Atribuicao",
      };

      const dataArray = JSON.parse(this.resultadoTabela);

      this.valoresTabelaGrupos = dataArray.map((item) => {
        const objeto = {};
        Object.keys(dicionario).forEach((header) => {
          let value = item[dicionario[header]] || "";
          if (header === "Data_Entrada") {
            value = this.formatarData(value);
          } else if (header === "Hora") {
            value = this.formatarHora(value);
          }
          objeto[header] = value;
        });

        return {
          Grupo: objeto["Grupo"],
          CPF: objeto["CPF"],
          Nome: objeto["Nome"],
          Data_Hora_Entrada:
            objeto["Data_Entrada"] && objeto["Hora"]
              ? `${objeto["Data_Entrada"]} ${objeto["Hora"]}`
              : "",
          Score_Churn: objeto["Score_Churn"],
          Score_Inad: objeto["Score_Inad"],
          Id_Atribuicao: objeto["Id_Atribuicao"],
        };
      });

      this.historicoValoresSumario = JSON.parse(
        JSON.stringify(this.valoresTabelaStatus)
      );
      this.agruparTabelaStatus();
    },

    agruparTabelaStatus() {
      const dicionario = {
        Grupo: "Grupo",
        Qtd_Cotas: "Qt_Cotas",
        Media_Churn: "Media_Churn",
        Media_Inad: "Media_Inad",
      };

      const dataArray = JSON.parse(this.resultadoSumario);

      this.valoresTabelaStatus = dataArray.map((item) => {
        const objeto = {};
        Object.keys(dicionario).forEach((header) => {
          let value = item[dicionario[header]] || "";
          objeto[header] = value;
        });
        return objeto;
      });

      this.valoresTabelaStatus.forEach((obj) => {
        obj.Media_Churn = Math.floor(obj.Media_Churn);
        obj.Media_Inad = Math.floor(obj.Media_Inad);
      });
    },

    formatarData(data) {
      if (!data) return data;

      if (/\d{2}\/\d{2}\/\d{4}/.test(data)) {
        return data;
      }
      const [ano, mes, dia] = data.split("-");
      return `${dia}/${mes}/${ano}`;
    },

    formatarHora(hora) {
      if (!hora) return hora;

      if (/^\d{2}:\d{2}$/.test(hora)) {
        return hora;
      }
      return hora;
    },

    async recuperaTabelas() {
      const tabelas = await chatService.recuperaTabelaGruposConsorcio();

      if (Object.keys(tabelas).length > 0) {
        this.resultadoTabela = tabelas.Tabela;
        this.resultadoSumario = tabelas.Sumario;
      }

      this.carregarTabela();
    },

    ...mapActions(chatOutboxStore, ["requisicaoAtribuiGrupo"]),

    ...mapActions(produtosStore, [
      "resetaCodigoAgrupador",
      "resetStore",
      "geraCodigoAgrupadorMetadados",
    ]),

    ...mapActions(userPreferenceStore, [
      "syncPreferencias",
      "createPreferencia",
      "substituiPreferencia",
      "syncPreferenciaGeral",
    ]),
  },

  computed: {
    listaInputs() {
      return inputsModelos;
    },

    valoresTabelaGruposFiltrada() {
      const texto = this.textoPesquisa.toLowerCase();

      return this.valoresTabelaGrupos.filter((obj) => {
        return Object.values(obj).some((value) => {
          if (value && typeof value === "string") {
            return value.toLowerCase().includes(texto);
          }
          return false;
        });
      });
    },

    ...mapWritableState(chatOutboxStore, {
      contexto: "contexto",
      resultadoRequisição: "resultadoRequisição",
      resultadoRequisiçãoAtribuiGrupo: "resultadoRequisiçãoAtribuiGrupo",
      cpfEhFavorito: "cpfEhFavorito",
    }),

    ...mapWritableState(produtosStore, {
      codigo_agrupador: "codigo_agrupador",
      codigo_agrupador_box: "codigo_agrupador_box",
    }),

    ...mapWritableState(userPreferenceStore, {
      usuarioLogado: "usuarioLogado",
      termosUsoAssistente: "termosUsoAssistente",
    }),
  },

  async mounted() {
    this.carregandoTabela = true;
    await this.recuperaTabelas();

    this.carregandoTabela = false;
    // if (this.tabelaGrupos) {
    // }
  },
};
</script>

<style lang="scss" scoped>
.container-consulta {
  width: 60%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;

  @include mediaQueryMax(medium) {
    width: 80%;
  }
}

.card-consulta {
  width: 100%;
  display: flex;
  padding: 10px;
  gap: 10px;
  min-width: 300px;
}

.titulo-box {
  display: flex;
  justify-content: space-between;
  cursor: default;
}

.item {
  padding: 0;
}

.item-titulo {
  font-size: 16px;
  line-height: 18px;

  .titulo {
    font-weight: 300;
  }
  .valor {
    font-weight: 400;
  }
}

.item-descricao {
  font-size: 14px;
  font-weight: 300;
  line-height: 16px;
}

.aviso {
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
}

.resultados-box {
  display: flex;
  justify-content: space-between;

  &.alterando {
    opacity: 0.5;
  }
}

.botao-cancelar {
  height: 0;
}

.botao-acao {
  background-color: transparent;
  border: none;
  padding: 5px 10px;
  color: white;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  transition: 0.3s;
  background-image: url(/img/tela-inicial/Botao-Nova.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  text-transform: uppercase;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 140px;
  height: 40px !important;

  &.cancelar {
    line-height: 13px;
  }

  &:hover {
    transform: scale(1.05);
  }
  &:active {
    transform: scale(0.95);
  }

  &.disabled {
    background-image: url(/img/tela-inicial/Botao-Nova-cinza.png);
    pointer-events: none;
    opacity: 0.7;
  }
}

.icone-reset {
  color: #a8a8a8;
  cursor: pointer;
  position: absolute;
  z-index: 10;
  top: -30px;
  right: 0;

  &:hover {
    color: #757575;
  }
}

.field-pesquisar {
  width: 230px;
  margin: 0;

  @include mediaQueryMax(small) {
    width: 100px;
  }
}

.favoritos {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 300px;
  margin: 0 0 0px 0;

  .card-fav {
    background: transparent;
    flex-direction: row;
  }
  .card-header {
    width: fit-content;
    border: none;
    box-shadow: none;
    max-height: 43px;
  }
  .content-fav {
    width: 100%;
    display: flex;
  }
  .selectFav {
    margin: 0 10px 0 0;
    width: 100%;
  }
  .star {
    margin: 0 5px 0px 0;
    color: #2196f3;
    opacity: 0.4;
    font-size: 18px;
    cursor: default;

    &.vazio {
      color: gray;
    }
  }
}

.disabled {
  pointer-events: none;
  color: #a1a1a1;
}
</style>
