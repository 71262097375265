<template>
  <div class="container">
    <table v-if="paginatedData.length > 0">
      <thead>
        <tr>
          <th
            v-for="(col, index) in headerGrupos"
            :key="col"
            class="cabecalho"
            @click="sortColumn(col)"
            style="cursor: pointer"
            :class="{
              indice: index == 0,
              grupo: index == 1,
              cpf: index == 2,
              nome: index == 3,
              data: index == 4,
              score: index == 5 || index == 6,
            }"
          >
            <span class="sub">
              {{ col }}
              <span
                v-if="col != ''"
                class="sort-arrow"
                :class="{ active: sortedColumn === col }"
              >
                {{
                  sortedColumn === col && sortDirection === "asc" ? "▲" : "▼"
                }}
              </span>
            </span>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(row, index) in filteredData"
          :key="index"
          class="linhas"
          :class="{
            'nova-linha': isNovaLinha(row),
          }"
        >
          <div class="cell indice">
            <span
              v-if="isNovaLinha(row)"
              class="alerta-novo"
              :class="{
                'ultima-linha': isPrimeiraNovaLinha(row),
              }"
            >
              NEW
            </span>
          </div>
          <td v-for="(value, key) in row.displayRow" :key="key" class="cell">
            {{ value }}
          </td>
        </tr>
      </tbody>
    </table>
    <div class="aviso" v-else>Nenhuma cota adicionada</div>
    <div class="paginas">
      <span
        class="material-symbols-outlined pag-botao"
        :class="{ disabled: currentPage === 1 }"
        @click="irParaPagina(1)"
      >
        keyboard_double_arrow_left
      </span>

      <span
        class="material-symbols-outlined pag-botao"
        :class="{ disabled: currentPage === 1 }"
        @click="paginaAnterior"
      >
        chevron_left
      </span>

      <span class="box-pagina" v-for="pagina in paginasVisiveis" :key="pagina">
        <button
          @click="irParaPagina(pagina)"
          :class="['botao-pagina', { 'pagina-ativa': pagina === currentPage }]"
        ></button>
        <span class="legenda-pagina">{{ pagina }}</span>
      </span>

      <span
        class="material-symbols-outlined pag-botao"
        :class="{ disabled: currentPage === totalPaginas }"
        @click="proximaPagina"
      >
        chevron_right
      </span>

      <span
        class="material-symbols-outlined pag-botao"
        :class="{ disabled: currentPage === totalPaginas }"
        @click="irParaPagina(totalPaginas)"
      >
        keyboard_double_arrow_right
      </span>
    </div>
    <!-- <div v-if="valoresTabelaGrupos.length > rowsPerPage" class="pagination">
      <span
        class="material-symbols-outlined seta-pagina"
        :class="{ disabled: currentPage === 1 }"
        @click="previousPage"
      >
        keyboard_double_arrow_left
      </span>
      <span class="texto-pagina"
        >Página {{ currentPage }} de {{ totalPages }}</span
      >
      <span
        class="material-symbols-outlined seta-pagina"
        :class="{ disabled: currentPage === totalPages }"
        @click="nextPage"
      >
        keyboard_double_arrow_right
      </span>
    </div> -->
  </div>
</template>

<script>
import Loading from "@/components/Loading.vue";
import inputsModelos from "@/helpers/inputs_modelos.json";

import { mapWritableState, mapActions } from "pinia";
import { userPreferenceStore } from "@/stores/userPreference";
import { dadosConsultaAddStore } from "@/stores/dadosConsultaAdd";
import { lotesService } from "@/services";

export default {
  name: "tabelaGruposConsorcios",

  props: {
    valoresTabelaGrupos: Array,
    historicoNovasCotas: Array,
  },

  data() {
    return {
      headerGrupos: [
        "",
        "Grupo",
        "CPF",
        "Nome",
        "Data e Hora Entrada",
        "Score Churn",
        "Score Inad.",
      ],

      keyMap: {
        Grupo: "Grupo",
        CPF: "CPF",
        Nome: "Nome",
        "Data e Hora Entrada": "Data_Hora_Entrada",
        "Score Churn": "Score_Churn",
        "Score Inad.": "Score_Inad",
      },

      currentPage: 1,
      linhasPorPagina: 7,
      rowsPerPage: 7,
      sortedColumn: "",
      sortDirection: "",
    };
  },

  components: {},

  methods: {
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },

    paginaAnterior() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },

    proximaPagina() {
      if (this.currentPage < this.totalPaginas) {
        this.currentPage++;
      }
    },

    irParaPagina(pagina) {
      this.currentPage = pagina;
    },

    isNovaLinha(row) {
      if (
        this.historicoNovasCotas.length > 0 &&
        this.historicoNovasCotas.some(
          (cota) => cota.Id_Atribuicao === row.Id_Atribuicao
        )
      ) {
        return true;
      }
      return false;
    },

    isPrimeiraNovaLinha(row) {
      return (
        this.historicoNovasCotas.length > 0 &&
        this.historicoNovasCotas[0].Id_Atribuicao === row.Id_Atribuicao
      );
    },

    sortColumn(col) {
      if (this.sortedColumn === col) {
        if (this.sortDirection === "asc") {
          this.sortDirection = "desc";
        } else if (this.sortDirection === "desc") {
          this.sortedColumn = null;
          this.sortDirection = null;
        } else {
          this.sortDirection = "asc";
        }
      } else {
        this.sortedColumn = col;
        this.sortDirection = "asc";
      }
    },

    ...mapActions(userPreferenceStore, [
      "recuperaUsuarioLogado",
      "createPreferencia",
      "substituiPreferencia",
      "syncPreferenciaGeral",
      "syncPreferenciaExibeParametros",
      "syncPreferenciaOcultarDados",
    ]),

    ...mapActions(dadosConsultaAddStore, ["resetConsutaAdd"]),
  },

  computed: {
    paginatedData() {
      const start = (this.currentPage - 1) * this.rowsPerPage;
      const end = start + this.rowsPerPage;
      return this.sortedData.slice(start, end);
    },

    filteredData() {
      return this.paginatedData.map((row) => ({
        ...row,
        displayRow: Object.entries(row)
          .filter(([key]) => key !== "Id_Atribuicao")
          .reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {}),
      }));
    },

    paginasVisiveis() {
      const total = this.totalPaginas;

      if (total <= 5) {
        return Array.from({ length: total }, (_, i) => i + 1);
      }

      let startPage = Math.max(1, this.currentPage - 2);
      let endPage = Math.min(total, startPage + 4);

      if (endPage === total) {
        startPage = Math.max(1, total - 4);
      }

      return Array.from({ length: 5 }, (_, i) => startPage + i);
    },

    sortedData() {
      if (
        !this.sortedColumn ||
        this.sortedColumn == "" ||
        this.sortedColumn == null
      ) {
        return [...this.valoresTabelaGrupos].sort((a, b) => {
          const parseDateTime = (dateTime) => {
            if (!dateTime) return null;
            const [datePart, timePart] = dateTime.split(" ");
            const [day, month, year] = datePart.split("/").map(Number);
            const [hours, minutes, seconds] = timePart.split(":").map(Number);
            return new Date(year, month - 1, day, hours, minutes, seconds);
          };

          const valA = parseDateTime(a["Data_Hora_Entrada"]);
          const valB = parseDateTime(b["Data_Hora_Entrada"]);

          return valB - valA;
        });
      }

      const columnKey = this.keyMap[this.sortedColumn];

      return [...this.valoresTabelaGrupos].sort((a, b) => {
        let valA = a[columnKey];
        let valB = b[columnKey];

        switch (this.sortedColumn) {
          case "Grupo":
          case "Nome":
            valA = String(valA || "").toLowerCase();
            valB = String(valB || "").toLowerCase();
            return this.sortDirection === "asc"
              ? valA.localeCompare(valB)
              : valB.localeCompare(valA);

          case "CPF":
          case "Score Churn":
          case "Score Inad.":
            valA = parseFloat(valA) || 0;
            valB = parseFloat(valB) || 0;
            return this.sortDirection === "asc" ? valA - valB : valB - valA;

          case "Data e Hora Entrada":
            const parseDateTime = (dateTime) => {
              if (!dateTime) return null;
              const [datePart, timePart] = dateTime.split(" ");
              const [day, month, year] = datePart.split("/").map(Number);
              const [hours, minutes, seconds] = timePart.split(":").map(Number);
              return new Date(year, month - 1, day, hours, minutes, seconds);
            };

            valA = parseDateTime(valA);
            valB = parseDateTime(valB);

            return this.sortDirection === "asc" ? valA - valB : valB - valA;

          default:
            return 0;
        }
      });
    },

    totalPages() {
      return Math.ceil(
        (this.valoresTabelaGrupos.length - 1) / this.rowsPerPage
      );
    },

    totalPaginas() {
      return Math.ceil(this.valoresTabelaGrupos.length / this.linhasPorPagina);
    },

    ...mapWritableState(userPreferenceStore, {
      usuarioLogado: "usuarioLogado",
      parametrosSugeridos: "parametrosSugeridos",
      exibirFavoritos: "exibirFavoritos",
      ocultarDados: "ocultarDados",
    }),
  },

  async mounted() {},
};
</script>

<style scoped lang="scss">
.container {
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0;
}

.cabecalho {
  font-size: 14px;
  font-weight: 400;
  min-width: fit-content;
  text-align: center;
  line-height: 14px;
  height: 25px;

  &.grupo {
    width: 20%;
  }
  &.cpf {
    width: 20%;
  }
  &.nome {
    width: 20%;
  }
  &.data {
    width: 20%;
    min-width: 140px;
  }
  &.score {
    width: 9%;
    min-width: 85px;
  }
}
.indice {
  width: 20px;
}

.sub {
  display: flex;
  justify-content: center;
  align-items: center;
}

.linhas {
  background-color: #ececec;
  border-bottom: 1px solid gray;
  height: 35px;
  cursor: default;

  &:hover {
    background-color: #e0e0e0;
  }

  &.nova-linha {
    background-color: #c5cef7a8;
    &:hover {
      background-color: #c5cef7;
    }
  }
}

.alerta-novo {
  position: absolute;
  left: 20px;
  font-size: 7px;
  border: 2px solid gray;
  padding: 0px 2px;
  border-radius: 3px;
  margin: 11px 0 0 3px;
  color: gray;
  font-weight: 600;
  line-height: 13px;

  &.ultima-linha {
    border: 2px solid #b71c1c;
    color: #b71c1c;
  }
}

.sort-arrow {
  margin-left: 5px;
  opacity: 0.5;
}

.sort-arrow.active {
  opacity: 1;
}

.pagination {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.texto-pagina {
  font-size: 12px;
  font-weight: 300;
  line-height: 14px;
}

.seta-pagina {
  margin: 0px;
  padding: 5px;
  cursor: pointer;
  color: #1da0f2;
  user-select: none;
}

.disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.cell {
  font-size: 14px;
  font-weight: 300;
  text-align: center;
}

.aviso {
  font-size: 14px;
  font-weight: 300;
  text-align: center;
}

.paginas {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  padding: 10px;
}

.pag-botao {
  user-select: none;
  color: #1da0f2;
  cursor: pointer;
}

.box-pagina {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 10px;
  font-weight: 300;
  line-height: 16px;
  color: #1da0f2;
}

.botao-pagina {
  width: 10px;
  height: 10px;
  border: 2px solid #1da0f2;
  border-radius: 50%;
  background-color: transparent;
  margin: 4px;
  cursor: pointer;
  padding: 0;
}

.botao-pagina.pagina-ativa {
  background-color: #1da0f2;
}

.legenda-pagina {
  position: absolute;
  transform: translateY(15px);
}

.disabled {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.5;
}
</style>
