<template>
  <div class="carrossel_container" @click="fechaCarrossel()">
    <Flicking
      class="carrossel_box"
      :options="options"
      :plugins="plugins"
      ref="flicking"
    >
      <div
        v-for="(card, index) in conteudoBlog"
        :key="index"
        class="slide-card"
      >
        <div class="card">
          <img :src="card.imagem" alt="" class="img" />
          <div class="titulo">
            {{ card.titulo }}
          </div>

          <div class="descricao">
            <div v-html="card.descricao"></div>
          </div>
        </div>
      </div>

      <span
        slot="viewport"
        class="flicking-arrow-prev-singu"
        @click="arrowClick($event, 'prev')"
      ></span>
      <span
        slot="viewport"
        class="flicking-arrow-next-singu"
        @click="arrowClick($event, 'next')"
      ></span>
      <div
        slot="viewport"
        class="flicking-pagination"
        :class="{ hidden: isAnimating }"
        @click.stop
      ></div>
    </Flicking>
  </div>
</template>

<script>
import { Flicking } from "@egjs/vue-flicking";
import "@egjs/vue-flicking/dist/flicking.css";
import { Pagination } from "@egjs/flicking-plugins";
import "@egjs/flicking-plugins/dist/pagination.css";

import listaBlog from "@/helpers/lista_carrossel_singu.json";

export default {
  name: "CarrosselSingu",
  components: {
    Flicking: Flicking,
  },
  data() {
    return {
      options: {
        circular: true,
        align: "center",
        changeOnHold: true,
        defaultIndex: 0,
      },

      plugins: [new Pagination({ type: "bullet" })],

      isTransitioning: false,
      isAnimating: false,

      flickingAnimation: false,
    };
  },

  methods: {
    fechaCarrossel() {
      this.$emit("fechaCarrossel");
    },

    arrowClick(event, direction) {
      event.stopPropagation();

      if (this.isAnimating) {
        return;
      } else {
        if (direction === "prev") {
          this.$refs.flicking.prev();
        } else if (direction === "next") {
          this.$refs.flicking.next();
        }
      }
    },
  },

  computed: {
    conteudoBlog() {
      return listaBlog;
    },
  },

  mounted() {
    const flicking = this.$refs.flicking;
    setInterval(() => {
      this.isAnimating = flicking.animating;
    }, 10);
  },
};
</script>

<style lang="scss" scoped>
.carrossel_container {
  width: 100%;
  height: 100%;
  padding: 0px 0 0 0;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.carrossel_box {
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 100%;
}

.slide-card {
  height: 100%;
  width: 100%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card {
  background-color: transparent;
  display: flex;
  flex-direction: column;
  place-items: start;
  transition: 0.5s;
  height: 100%;
  width: 100%;
  margin: 20px 10px;
  border: none;

  .img {
    border-radius: 17px;
    margin-bottom: 5px;
  }
  .titulo {
    color: #505050;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    margin: 10px 0;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .descricao {
    color: #505050;
    width: 100%;
    font-size: 16px;
    line-height: 21px;
    text-align: justify;
  }
}
</style>
<style lang="scss">
.flicking-arrow-prev-singu,
.flicking-arrow-next-singu {
  width: 35px;
  height: 35px;
  bottom: 0px;
  position: absolute;
  z-index: 2;
  cursor: pointer;
  transform: translateY(-50%);
}

.flicking-arrow-prev-singu {
  left: 20px;
}

.flicking-arrow-next-singu {
  right: 20px;
}

.flicking-arrow-prev-singu::before,
.flicking-arrow-prev-singu::after,
.flicking-arrow-next-singu::before,
.flicking-arrow-next-singu::after {
  background-color: #bfbdbd !important;
  width: 24px;
  height: 6px;
  position: absolute;
  content: "";
}

.flicking-arrow-prev-singu::before {
  transform: rotate(-45deg);
  transform-origin: 0% 50%;
  left: calc(50% - 20px);
  top: 50%;
}

.flicking-arrow-prev-singu::after {
  right: 13px;
  top: calc(52% - 4px);
  transform: rotate(45deg);
  transform-origin: 0% 50%;
}

.flicking-arrow-next-singu::before {
  right: calc(50% - 20px);
  top: 50%;
  transform: rotate(45deg);
  transform-origin: 100% 50%;
}

.flicking-arrow-next-singu::after {
  left: 13px;
  top: calc(52% - 4px);
  transform: rotate(-45deg);
  transform-origin: 100% 50%;
}

.flicking-pagination {
  display: flex;
  justify-content: center;
  position: absolute;
  left: 0;
  // bottom: 15% !important;
  width: 100%;
  text-align: center;
  z-index: 2;
}

.flicking-pagination .flicking-pagination-bullet-active {
  background-color: #063f6ea6 !important;
}

.flicking-pagination-bullet {
  width: 10px !important;
  height: 10px !important;
  display: inline-block;
  margin: 0 4px;
  border-radius: 50%;
  background-color: rgb(10 10 10 / 10%);
  cursor: pointer;
  font-size: 1rem;
}
</style>
