<template>
  <div class="dropdown" :class="{ 'dropdown--open': openChild }">
    <div class="header tit" @click="openChild = !openChild">
      <div class="header__esq">
        <span class="nome">
          <span class="material-symbols-outlined iconAtr"> check_small </span>
          {{ nome }}
        </span>
        <span class="descricao">{{ descricao }} </span>
      </div>
      <div class="header__dir">
        <span
          class="material-symbols-outlined iconbox"
          :class="{ 'icon-open': openChild }"
          v-if="tipoPessoa == 'Física' || tipoPessoa == 'Indiferente'"
          >face</span
        >
        <span
          class="material-symbols-outlined iconbox"
          :class="{ 'icon-open': openChild }"
          v-if="tipoPessoa == 'Jurídica' || tipoPessoa == 'Indiferente'"
        >
          business
        </span>
        <span
          class="material-symbols-rounded"
          v-if="!openChild"
          @click="openChild = !openChild"
        >
          expand_more
        </span>
        <span
          class="material-symbols-rounded"
          v-if="openChild"
          @click="openChild = !openChild"
        >
          expand_less
        </span>
      </div>
    </div>
    <div class="corpo" :class="{ 'corpo--aberto': openChild }">
      <div class="dropdown2" :class="{ 'dropdown--open': openChild2 }">
        <div class="header atr">
          <div class="header__esq" @click="openChild2 = !openChild2">
            <span class="nomeAtr">
              <span class="material-symbols-outlined iconAtr"> sort </span>
              <span> Atributos </span>

              <md-progress-spinner
                v-if="liberaSpinnerAtr"
                :md-diameter="20"
                :md-stroke="2"
                class="spinner"
                md-mode="indeterminate"
              >
              </md-progress-spinner>
            </span>
          </div>
          <div class="header__dir">
            <span class="searchBox" v-if="openChild2">
              <md-field
                class="searchInput md-layout-item md-size-45 md-medium-size-50 md-xsmall-size-100"
              >
                <label
                  for="pesquisaBooks"
                  style="margin: 0px;"
                >
                  <span v-if="alertAtributo"> 'Atributo.' desconsiderado </span>
                  <span v-else> Pesquisar por Atributos da Box </span>
                </label>
                <md-input
                  id="pesquisaBooks"
                  v-model="pesquisaAtr"
                  @input="filtraAtributo()"
                  @click.stop
                  :disabled="liberaSpinnerAtr"
                ></md-input>
                <span class="material-symbols-outlined icon">search</span>
              </md-field>
            </span>
            <span
              class="material-symbols-outlined download"
              @click="exportarPlanilha($event)"
              :class="{ 'btn-disabled': liberaSpinnerAtr }"
            >
              download
            </span>
            <span
              class="material-symbols-rounded"
              v-if="!openChild2"
              @click="openChild2 = !openChild2"
            >
              expand_more
            </span>
            <span
              class="material-symbols-rounded"
              v-if="openChild2"
              @click="openChild2 = !openChild2"
            >
              expand_less
            </span>
          </div>
        </div>
        <div class="corpo" :class="{ 'corpo--aberto': openChild2 }">
          <table
            id="tableComponent"
            class="table"
            v-if="listaAtributos.length > 0"
          >
            <thead>
              <tr>
                <th v-for="field in fields" :key="field" class="text-left">
                  {{ field }}
                </th>
              </tr>
            </thead>
            <tbody class="table-body">
              <BoxListaAtributos
                v-for="(atributo, index) in listaAtributos"
                :key="index"
                :atributos="listaAtributos"
                :atributo="atributo"
                :index="index"
                :pesquisaAtr="pesquisaAtrControl"
              >
              </BoxListaAtributos>
            </tbody>
          </table>
          <div v-if="listaAtributos.length == 0 && pesquisaAtr == ''">
            Aguarde...
          </div>
          <div v-if="listaAtributos.length == 0 && pesquisaAtr != ''">
            Nenhum valor encontrado
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BoxListaAtributos from "@/components/Books/BoxListaAtributos.vue";
import { lotesService } from "@/services";
import { createBox } from "@/services/planilha.service.js";

export default {
  name: "DropdownBox",
  components: {
    BoxListaAtributos,
  },
  props: {
    nome: String,
    descricao: String,
    tipoPessoa: String,
    tipoBox: String,
    pesquisaAtrRouter: String,
  },

  data() {
    return {
      openChild: false,
      openChild2: false,
      fields: ["Identificador", "Descrição", "Tipo"],
      listaAtributos: [],
      listaAtributosControle: [],
      layoutBox: {},
      liberaSpinnerAtr: false,
      pesquisaAtr: "",
      pesquisaAtrControl: "",
      alertAtributo: false,
    };
  },

  computed: {
    atributosFiltrados() {
      if (this.pesquisaAtr.trim().length > 0) {
        let searchTerm = this.pesquisaAtr.toLowerCase();

        return this.listaAtributos.filter(
          (item) =>
            item.Identificador.toLowerCase().includes(searchTerm) ||
            item.Descricao.toLowerCase().includes(searchTerm)
        );
      }
      return this.listaAtributos;
    },
  },

  methods: {
    exportarPlanilha(event) {
      event.stopPropagation();
      createBox(this.layoutBox, this.nome);
    },

    async recuperaLayoutAtr(box) {
      this.liberaSpinnerAtr = true;
      this.layoutBox = {};
      const choosedBox = box;

      const objetoHistorico = {
        cod_ref: choosedBox,
      };
      const query = new URLSearchParams(objetoHistorico);
      this.layoutBox = await lotesService.recuperaLayoutAtributo(
        query.toString()
      );
      this.listaAtributos = [];
      this.listaAtributos = this.layoutBox.ListaAtributos;

      this.listaAtributosControle = this.listaAtributos;
      this.liberaSpinnerAtr = false;
    },

    async recuperaLayoutMod(box) {
      this.liberaSpinnerAtr = true;
      this.layoutBox = {};
      const choosedBox = box;

      const objetoHistorico = {
        cod_ref: choosedBox,
      };
      const query = new URLSearchParams(objetoHistorico);
      this.layoutBox = await lotesService.recuperaLayoutModelo(
        query.toString()
      );
      this.listaAtributos = [];
      this.listaAtributos = this.layoutBox.ListaAtributos;

      this.listaAtributosControle = this.listaAtributos;
      this.liberaSpinnerAtr = false;
    },

    filtraAtributo() {
      this.listaAtributos = this.listaAtributosControle;
      this.pesquisaAtrControl = "";

      if (this.pesquisaAtr.trim().length > 0) {
        this.listaAtributos = this.atributosFiltrados;

        if (
          this.pesquisaAtr.startsWith("atributo.") ||
          this.pesquisaAtr.startsWith("atributo_")
        ) {
          this.pesquisaAtr = this.pesquisaAtr.slice(9);
          this.alertAtributo = true;
        } else {
          this.alertAtributo = false;
        }

        this.pesquisaAtrControl = this.pesquisaAtr;
      } else {
        this.listaAtributos = this.listaAtributosControle;
      }
    },
  },

  watch: {
    openChild() {
      if (this.openChild) {
        if (this.tipoBox == "Box de Atributos") {
          this.recuperaLayoutAtr(this.nome);
          return true;
        }
        if (this.tipoBox == "Box de Modelos") {
          this.recuperaLayoutMod(this.nome);
          return true;
        }
      }
      return false;
    },
  },

  mounted() {
    if (this.pesquisaAtrRouter != undefined && this.pesquisaAtrRouter != "") {
      this.openChild = true;
      this.openChild2 = true;
      this.pesquisaAtr = "";
      this.pesquisaAtr = this.pesquisaAtrRouter;
      this.filtraAtributo();
    }
  },
};
</script>

<style lang="scss" scoped>
.dropdown {
  padding: 0.5rem 0.5rem 0 0.5rem;
  color: $bg-sidebar;
  background: rgb(255, 255, 255);
  border-radius: 10px;
  max-width: 100%;
  border: 1px solid transparent;
  display: flex;
  flex-direction: column;
  z-index: 1;
  position: relative;
  transition: 1s ease all;

  &--open {
    border: 1px solid $accent;
    max-height: 500px;
    overflow: hidden;
    overflow-y: hidden;
  }
}

.dropdown2 {
  position: relative;
  padding: 0.5rem;
  color: $bg-sidebar;
  background: $white;
  z-index: 2;
  border-radius: 10px;
  max-width: 100%;
  margin: 5px 10px 10px 10px;
  border: 1px solid transparent;
  display: flex;
  flex-direction: column;
  box-shadow: 1px 0 10px #aaa;

  &.dropdown--open {
    .header__dir {
      margin: 0px 0 0 0;
    }
  }
  .header__esq {
    width: 50%;
  }
}

@keyframes sobe {
  0% {
    transform: translateY(200px);
  }

  100% {
    transform: translateY(0px);
  }
}

.header {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  &__esq {
    display: flex;
    flex-direction: column;

    .nome {
      color: $accent;
      font-weight: 600;
      font-size: 1.2rem;
      display: flex;
      align-items: flex-end;
    }
  }
}

.download {
  margin-right: 10px;
  cursor: pointer;
  color: $accent;

  &:hover {
    transform: scale(1.1);
  }
}

.nomeAtr {
  color: $accent;
  font-weight: 500;
  font-size: 1.1rem;
  display: flex;
}

.iconAtr {
  margin-right: 0.2rem;
}
.corpo {
  display: flex;
  flex-direction: column;
  overflow-x: auto;
  height: 0;

  &--aberto {
    height: fit-content;
    padding-top: 0rem;
  }
}

.descricao {
  font-size: 1rem;
  font-weight: 500;
  margin: 0 10px;
}

.header__dir {
  display: flex;
}

.icon {
  color: $accent;
  font-size: 1rem;
}
.spinner {
  margin: 4px 0 0 20px;
  height: 20px;
}

.iconbox {
  font-size: 70px;
  opacity: 0.1;
}

.icon-open {
  font-size: 100px;
  user-select: none;
}

.searchBox {
  display: flex;
  align-items: flex-end;
  margin: 7px 15px 0 15px;
  height: 40px;
}

.searchInput {
  margin: 10px 10px 0 0;
  padding: 5px 0;
  border-radius: 20%;
  min-width: 250px !important;
}

.icon {
  font-size: 18px;
  padding: 0;
  height: 18px;
  cursor: default;
  color: black;
}

.btn-disabled {
  cursor: not-allowed !important;
  pointer-events: none !important;
  opacity: 0.5;
}
</style>
