<template>
  <router-view></router-view>
</template>

<script>
export default {};
</script>

<style>
::-webkit-scrollbar-track {
    background-color: #F4F4F4;
}
::-webkit-scrollbar {
    width: 15px;
    background: #F4F4F4;
}
::-webkit-scrollbar-thumb {
    background: #c4c4c4;
    border-radius: 5px;
}

.sidebar-wrapper::-webkit-scrollbar {
    width: 5px;
    background: #3B3B3B;
}

.sidebar-wrapper::-webkit-scrollbar-track {
    background-color: #3B3B3B;
}
</style>
